import * as S from './styles'
import { useEffect, useState } from 'react'
import CardProduto from '../../../components/CardProduto'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { getProdutosFiltro as getProdutosFiltroSelector } from '../../../shared/redux/selectors'
import { Produto as ProdutoDictionary } from '../../../shared/utils/dictionary'

const Conteudo = () => {

    const estoque = useSelector(getProdutosFiltroSelector).filter(x => x[ProdutoDictionary.SITUACAO] === 1)

    const [corte, setCorte] = useState(30)

    useEffect(() => {
        if(estoque.length < 30) {
            setCorte(estoque.length > 30 ? 30 : estoque.length)
        }
    },[estoque])

    return (
        <S.Container>
            <h3 className='vizualizacao'>Vizualizando - {corte} de {estoque.length}</h3>
            <div className='grid'>
                {
                    estoque.length === 1 ? (
                        estoque.slice(0, corte).map((x) => (
                            <>
                                <CardProduto estoque produto={x} key={x[ProdutoDictionary.ID]} />
                            </>
                            ))
                    ) : (
                        estoque.slice(0, corte).map((x, index) => (
                            <CardProduto estoque produto={x} key={x[ProdutoDictionary.ID]} />
                            ))
                    )
                }
                
            </div>
            <span className='div-end'>
                <h3 className='vizualizacao'><b>Carregando {corte} de {estoque.length}</b></h3>
                {estoque.length === corte ? null : (
                    <Button disabled={estoque.length === corte} className='button' onClick={()=>{
                    if(estoque.length > corte + 30) {
                        setCorte(corte + 30)
                    } else {
                        setCorte(estoque.length)
                    }
                }}>Ver mais</Button>
                )}
            </span>
        </S.Container>
    )
}

export default Conteudo;