import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { AsideAdmin } from '../AsideAdmin'
import { Button, Col, DatePicker, Dropdown, Form, Input, Menu, Modal, Row, Switch } from 'antd'
import { getCampanhas as getCampanhasSelector } from '../../../shared/redux/selectors'
import { Campanha as CampanhaDictionary } from '../../../shared/utils/dictionary'
import { getCampanhas } from '../../../shared/redux/slices/loja'
import { useEffect, useState } from 'react'
import { deleteCampanha, editCampanha, postCampanha } from '../../../service/apiAdmin'
import { toast } from 'react-toastify'
import moment from 'moment'

export const Campanha = () => {
    const dispatch = useDispatch<AppDispatch>()
    const campanha = useSelector(getCampanhasSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditarOpen, setIsModalEditarOpen] = useState(false);
    const [editObj, setEditObj] = useState<any>(null);
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);

    const onChange = (checked: boolean) => {
        setDisabled(checked);
    };

    useEffect(() => {
        dispatch(getCampanhas())
    },[])

        const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <i className="ri-pencil-line"></i>
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <i style={{color: "red"}} className="ri-delete-bin-6-line"></i>
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];
    
      const handleCancel = () => {
        if(isModalEditarOpen) {
            window.location.reload()
        }
          form.resetFields();
        setIsModalOpen(false);
        setIsModalEditarOpen(false);
      };

      const onFinish = async (values: any) => {
        const data = {
            nome: values.nome,
            dataFim: values.dataFim.toISOString()?.split("T")[0],
            dataInicio: values.dataInicio.toISOString()?.split("T")[0],
            situacao: disabled
        }
        if(isModalEditarOpen) {
            try {
                await editCampanha(data, editObj[CampanhaDictionary.ID])
                form.resetFields();
                setIsModalOpen(false)
                setIsModalEditarOpen(false)
                window.location.reload()
            } catch {
                toast.error("Ocorreu um erro ao criar campanha, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        } else {
            try {
                await postCampanha(data)
                form.resetFields();
                window.location.reload()
                setIsModalOpen(false)
            } catch {
                toast.error("Ocorreu um erro ao criar campanha, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        }
      };

      useEffect(() => {
        if (isModalEditarOpen && editObj) {
          form.setFieldsValue({
            nome: editObj[CampanhaDictionary.NOME],
            dataInicio:moment(editObj[CampanhaDictionary.DATA_INICIO]),
            dataFim: moment(editObj[CampanhaDictionary.DATA_FIM]),
            situacao: editObj[CampanhaDictionary.SITUACAO]
          });
          setDisabled(editObj[CampanhaDictionary.SITUACAO])
        } else {
          form.resetFields();
        }
      }, [isModalEditarOpen, editObj, form]);

    return (
        <S.Container>
            <Modal style={{overflow: "hidden"}} key={isModalEditarOpen ? editObj?.id : 'create'} title="Cadastrar Campanha" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <Form
                form={form}
                name="campanhas"
                initialValues={isModalEditarOpen ? {nome: editObj?.[CampanhaDictionary.NOME]} : undefined}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                label="Nome"
                name="nome"
                rules={[{ required: true, message: 'Por favor insira o nome da campanha!' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Date de início"
                name="dataInicio"
                rules={[{ required: true, message: 'Por favor insira a data de início!' }]}
                >
                <DatePicker />
                </Form.Item>

                <Form.Item
                label="Date do fim"
                name="dataFim"
                rules={[{ required: true, message: 'Por favor insira a data do fim!' }]}
                >
                <DatePicker />
                </Form.Item>

                <Form.Item
                label="Ativo"
                name="situacao"
                >
                <Switch size="small" checked={disabled} onChange={onChange} defaultChecked={isModalEditarOpen ? editObj[CampanhaDictionary.SITUACAO] : true} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
                </Form.Item>
            </Form>
            </Modal>
                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title-container'>
                        <div className='title'>
                            <i className="ri-bubble-chart-line ri-3x"></i>
                            <h1>Campanhas</h1>
                        </div>
                        <Button onClick={() => {
                            setIsModalOpen(true)
                            setIsModalEditarOpen(false)
                        }} type='primary'>Cadastrar nova campanha</Button>
                    </div>

                    <div>
                 <Row className='title-table'>
                     <Col span={3}>
                         <h4>Nome</h4>
                     </Col>
                     <Col span={3}>
                         <h4>Início</h4>
                     </Col>
                     <Col span={3}>
                         <h4>Fim</h4>
                     </Col>
                     <Col span={1}>
                         <h4>Ações</h4>
                     </Col>
                 </Row>
                 {campanha?.map((x) => (
                <Row key={x[CampanhaDictionary.ID]} className='title-table'>
                    <Col span={3}>
                        <h5>{x[CampanhaDictionary.NOME]}</h5>
                    </Col>
                    <Col span={3}>
                        <h5>{x[CampanhaDictionary.DATA_INICIO]}</h5>
                    </Col>
                    <Col span={3}>
                        <h5>{x[CampanhaDictionary.DATA_FIM]}</h5>
                    </Col>
                    <Col className='actions' span={1}>
                    <Dropdown placement="bottomLeft" overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const edit = campanha.find(m => m[CampanhaDictionary.ID] === x[CampanhaDictionary.ID])

                                if(item?.danger) {
                                    try {
                                        await deleteCampanha(x[CampanhaDictionary.ID].toString())
                                        dispatch(getCampanhas())
                                    } catch {
                                        toast.error(`Ocorreu um erro ao deletar campanha ${x[CampanhaDictionary.NOME]}`)
                                    }
                                    dispatch(getCampanhas())
                                } else {
                                    setEditObj(edit)
                                    setIsModalEditarOpen(true);
                                    setIsModalOpen(true)
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <i style={{color: "black"}} className="ri-file-list-line ri-lg"></i>
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}