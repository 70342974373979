import Header from '../../components/Header';
import * as S from './styles'
import { Breadcrumb, Modal } from 'antd';
import { Banner, Loja as LojaDictionary } from '../../shared/utils/dictionary'
import { isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getBanner, getLoja } from '../../shared/redux/selectors';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { postWhats } from '../../service/api';
import { useNavigate } from 'react-router-dom';
import GuiaTamanhos from '../../assets/images/guiaMedidas.png'
import { AppDispatch } from '../../App';
import { getBanners } from '../../shared/redux/slices/loja';

const Servicos: React.FC = () => {

    const loja = useSelector(getLoja)
    const [ip, setIP] = useState()
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const imagens = useSelector(getBanner)
    function createMarkup(html: any) {
        return { __html: html };
      }

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    useEffect(() => {
        dispatch(getBanners())
    },[])

    useEffect(() => {
        scrollToTop();
      }, []);

    const bannerPos3Mobile = imagens.filter((x) => x[Banner.POSICAO] === "3").filter((y) => y[Banner.MOBILE] === "MOBILE")
    const bannerPos3Desktop = imagens.filter((x) => x[Banner.POSICAO] === "3").filter((y) => y[Banner.MOBILE] === "DESKTOP")
    const bannerPos4Mobile = imagens.filter((x) => x[Banner.POSICAO] === "4").filter((y) => y[Banner.MOBILE] === "MOBILE")
    const bannerPos4Desktop = imagens.filter((x) => x[Banner.POSICAO] === "4").filter((y) => y[Banner.MOBILE] === "DESKTOP")

    return (
        <S.Container>
            <Header />
            <Modal
                width={isMobile() ? "90vw" : "40vw"}
                open={visible} 
                onCancel={() => {setVisible(false)}}
                footer={null}
            >
                <img style={{width: "-webkit-fill-available"}} src={GuiaTamanhos} alt="Imagem" />
            </Modal>
            <S.Content>
                <Breadcrumb className='breadcrumb' items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: 'Nossos serviços',
                    }
                    ]} />
                    <div className='titulo'>
                        <h1>O QUE NÓS REALIZAMOS AQUI NA CARVALHO</h1>
                        <h2>Alguma dúvida sobre nossos serviços? aqui você tem total conhecimento de tudo que fazemos.</h2>
                        <hr />
                    </div>
                    <div className='grid-card'>
                        <div className='card' onClick={() => {
                            setVisible(true)
                        }}>
                            <div className='icon'>
                                <i style={{color: "#025773"}} className="ri-pencil-ruler-2-line ri-5x"></i>
                                <h3>GUIA DE TAMANHOS</h3>
                            </div>
                            <h4>Encontre o tamanho certo para as suas joias aqui na Carvalho.</h4>
                            <h4 style={{fontWeight: 600}}>CHECAR TAMANHOS <i className="ri-arrow-right-s-line"></i></h4>
                        </div>
                        <div className='card' onClick={() => {
                            if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de vender uma joia minha`)
                        }}>
                            <div className='icon'>
                                <i style={{color: "#025773"}} className="ri-money-dollar-circle-line ri-5x"></i>
                                <h3>COMPRA DE PEÇAS</h3>
                            </div>
                            <h4>Querendo vender uma peça? nós compramos ouro e prata.</h4>
                            <h4 style={{fontWeight: 600}}>ENTRAR EM CONTATO <i className="ri-arrow-right-s-line"></i></h4>
                        </div>
                        <div className='card' onClick={() => {
                            if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de encomendar um produto`)
                        }}>
                            <div className='icon'>
                                <i style={{color: "#025773"}} className="ri-truck-line ri-5x"></i>
                                <h3>ENCOMENDA DE PEÇAS</h3>
                            </div>
                            <h4>Não achou o que procurava, mas tem interesse em encomendar uma peça?</h4>
                            <h4 style={{fontWeight: 600}}>ENTRAR EM CONTATO <i className="ri-arrow-right-s-line"></i></h4>
                        </div>
                        <div className='card'>
                            <div className='icon'>
                                <i style={{color: "#025773"}} className="ri-vip-diamond-line ri-5x"></i>
                                <h3>PEÇAS AUTÊNTICAS</h3>
                            </div>
                            <h4>Todas as peças que vendemos de outras marcas são 100% autênticas.</h4>
                        </div>
                    </div>
                    {isMobile() ? null : (
                        <div className='titulo'>
                            <h1>NOSSAS ESPECIALIDADES</h1>
                            <h2>A Joalheria Carvalho é especializada em concretizar seus sonhos.</h2>
                            <hr />
                        </div>
                    )}
                    <div className='background'>
                        {isMobile() ? (
                            <div className='container'>
                                <span>
                                <h2>Jóias feitas a mão</h2>
                                <p dangerouslySetInnerHTML={createMarkup(bannerPos3Mobile?.[0]?.[Banner.DESCRICAO])}></p>
                                    <h6 style={{cursor: "pointer", textAlign: "end"}} onClick={() => {
                                        navigate("/cotar")
                                    }}>COTAR UMA JOIA PERSONALIZADA <i className="ri-arrow-right-s-line"></i></h6>
                                </span>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${bannerPos3Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="Joia personalizada" />
                            </div>
                        ) : (
                            <div className='container'>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${bannerPos3Desktop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="Joia personalizada" />
                                <span>
                                <h2>Jóias feitas a mão</h2>
                                <p dangerouslySetInnerHTML={createMarkup(bannerPos3Desktop?.[0]?.[Banner.DESCRICAO])}></p>
                                    <h6 style={{cursor: "pointer"}} onClick={() => {
                                        navigate("/cotar")
                                    }}>COTAR UMA JOIA PERSONALIZADA <i className="ri-arrow-right-s-line"></i></h6>
                                </span>
                            </div>
                        )}
                        {isMobile() ? (
                            <div className='container'>
                                <span>
                                <h2 style={{textAlign: "end"}}>Conserto de jóia</h2>
                                <p dangerouslySetInnerHTML={createMarkup(bannerPos4Mobile?.[0]?.[Banner.DESCRICAO])}></p>
                                <h6 style={{cursor: "pointer", textAlign: "start"}} onClick={() => {
                                    if(!ip) {
                                        return
                                        } else {
                                        postWhats({
                                            ip: ip
                                        })
                                        }
                                    window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tenho interesse em comprar uma jóia`)
                                }}>PRECISO DE AJUDA EM UMA JOIA <i className="ri-arrow-right-s-line"></i></h6>
                                </span>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${bannerPos4Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="Joia personalizada" />
                            </div>
                        ) : (
                            <div className='container'>
                                <span>
                                <h2 style={{textAlign: "end"}}>Conserto de jóia</h2>
                                <p style={{maxWidth: "100%"}} dangerouslySetInnerHTML={createMarkup(bannerPos4Desktop?.[0]?.[Banner.DESCRICAO])}></p>
                                <h6 style={{cursor: "pointer", textAlign: "end"}} onClick={() => {
                                    if(!ip) {
                                        return
                                    } else {
                                        postWhats({
                                            ip: ip
                                        })
                                    }
                                    window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tenho interesse em comprar uma jóia`)
                                }}>PRECISO DE AJUDA EM UMA JOIA <i className="ri-arrow-right-s-line"></i></h6>
                                </span>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${bannerPos4Desktop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="Joia personalizada" />
                            </div>
                        )}
                    </div>
            </S.Content>
        </S.Container>
    )
}

export default Servicos;