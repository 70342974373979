import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    width: 70px;
    gap: 25px;
    background-color: #212529;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: 2px 2px 10px gray;
    z-index: 1;
    transition: 0.3s ease-in-out;
    overflow-x: none;
    overflow-y: auto;

    i {
        color: white;
    }
    >div {
        height: 30px;
        display: flex;
        margin-left: 24px;
        flex-direction: row;
        gap: 20px;
        align-items: flex-start;
    }
    h4 {
        display: none;
        font-family: Montserrat;
        font-weight: 300;
    }
    &:hover {
        width: 200px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;
        h4 {
            display: block;
            color: white;
        }
        >div {
            display: flex;
            margin-left: 24px;
            flex-direction: row;
            gap: 20px;
            align-items: flex-start;
        } 
    }
    div {
        cursor: pointer;
    }
`