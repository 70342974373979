import styled from 'styled-components';

export const Container = styled.div`

`

export const Content = styled.div`
    .breadcrumb {
        padding: 25px 100px;
    }
    .container {
        padding: 20px 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        gap: 20px;
        align-items: center;
    }
    .imagem {
        width: 50vw;
        height: auto;
    }
    .texto {
        font-weight: 500;
        font-size: 15px;
        max-width: 90%;
        text-align: justify;
        font-family: Montserrat;
    }
    h2 {
        font-weight: 500;
        font-size: 20px;
        font-variant: small-caps;
    }
    span {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
    @media (max-width: 991px) {
        span {
            text-align: justify;
            align-items: start;
        }
        .breadcrumb {
        padding: 10px;
        }
        .container {
            padding: 20px 10px;
            flex-direction: column;
        }
        .texto {
            max-width: 100%;
        }
        .imagem {
            width: inherit;
        }
    }
`