import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    margin-top: 15px;
    @media (max-width: 991px) {
        margin-bottom: 120px;
    }

    .grid{
       display: grid;
       gap: 20px;
       padding: 0 auto;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media (max-width: 1672px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (max-width: 1039px) {
            grid-template-columns: 1fr 1fr;
        }
    }
    
    .vizualizacao {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 15px;
    }

    .button {
        background-color: transparent;
        font-family: "Montserrat";
        font-weight: 500;
        width: 200px;
        font-size: 13px;
    }

    .div-end {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 991px) {
        .grid{
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
    }
`
