import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { AsideAdmin } from '../AsideAdmin'
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Upload } from 'antd'
import { getCategorias as getCategoriasSelector } from '../../../shared/redux/selectors'
import { Categoria as CategoriaDictionary } from '../../../shared/utils/dictionary'
import { getCategorias } from '../../../shared/redux/slices/loja'
import { useEffect, useState } from 'react'
import { deleteCategoria, editCategoria, postCategoria } from '../../../service/apiAdmin'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

export const Categoria = () => {
    const dispatch = useDispatch<AppDispatch>()
    const categorias = useSelector(getCategoriasSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditarOpen, setIsModalEditarOpen] = useState(false);
    const [editObj, setEditObj] = useState<any>(null);
    const [imagem, setImagem] = useState<any>();
    const usuarioCookies = Cookies.get('auth');
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getCategorias())
    },[])

        const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <i className="ri-pencil-line"></i>
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <i style={{color: "red"}} className="ri-delete-bin-6-line"></i>
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];
    
      const handleCancel = () => {
        if(isModalEditarOpen) {
            window.location.reload()
        }
          form.resetFields();
        setIsModalOpen(false);
        setIsModalEditarOpen(false);
      };

      const onFinish = async (values: any) => {
        if(isModalEditarOpen) {
            try {
                const formData = new FormData();
                formData.append('file', imagem);
                const response = await axios.post(`${process.env.REACT_APP_API}upload/1`, formData, {
                    headers: {
                        'token': usuarioCookies,
                        'Content-Type': 'multipart/form-data'
                      }
                    });

                    const dataCreate = {
                        nome: values.nome,
                        idImagem: response.data.id
                    }
                    await editCategoria(dataCreate, editObj[CategoriaDictionary.ID])
                    dispatch(getCategorias())
                    form.resetFields();
                    setIsModalOpen(false)
                    setIsModalEditarOpen(false)
                    window.location.reload()
                } catch {
                    toast.error("Ocorreu um erro ao criar categoria, tente novamente mais tarde ou entre em contato com nosso suporte.")
                }
            } else {
                const formData = new FormData();
                formData.append('file', imagem);
                const response = await axios.post(`${process.env.REACT_APP_API}upload/1`, formData, {
                    headers: {
                        'token': usuarioCookies,
                        'Content-Type': 'multipart/form-data'
                      }
                    });
                    
                    const dataCreate = {
                        nome: values.nome,
                        idImagem: response.data.id
                    }
                    await postCategoria(dataCreate)
                    window.location.reload()
                    form.resetFields();
                    setIsModalOpen(false)
        }
      };

      const handleUpload = async (file: any) => {
        setImagem(file)
        return false
      };

      useEffect(() => {
        if (isModalEditarOpen && editObj) {
            setImagem(editObj[CategoriaDictionary.FOTO][CategoriaDictionary.ARQUIVO])
          form.setFieldsValue({
            nome: editObj[CategoriaDictionary.NOME]
          });
        } else {
          form.resetFields();
        }
      }, [isModalEditarOpen, editObj, form]);

    return (
        <S.Container>
            <Modal style={{overflow: "hidden"}} key={isModalEditarOpen ? editObj?.id : 'create'} title="Cadastrar Categoria" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <Form
                form={form}
                name="categoria"
                initialValues={isModalEditarOpen ? {nome: editObj?.[CategoriaDictionary.NOME]} : undefined}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                label="Nome"
                name="nome"
                rules={[{ required: true, message: 'Por favor insira o nome da categoria!' }]}
                >
                <Input />
                </Form.Item>

                    <Form.Item
                    label="Imagem"
                    name="imagem"
                    rules={[{ required: true, message: 'Por favor insira uma imagem' }]}
                    >
                    <Upload
                    defaultFileList={[{
                        uid: '1',
                        name: 'default-image.jpg',
                        status: 'done',
                        url: isModalEditarOpen ? `${process.env.REACT_APP_API}${editObj[CategoriaDictionary.FOTO][CategoriaDictionary.ARQUIVO]}` : "",
                      }]}
                    action="/"
                    listType="picture"
                    beforeUpload={handleUpload}
                    maxCount={1}
                    >
                    <Button icon={<i className="ri-folder-upload-line"></i>}>Upload</Button>
                    </Upload>
                    </Form.Item>

                <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
                </Form.Item>
            </Form>
            </Modal>
                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title-container'>
                        <div className='title'>
                            <i className="ri-folder-line ri-3x"></i>
                            <h1>Categorias</h1>
                        </div>
                        <Button onClick={() => {
                            setIsModalOpen(true)
                            setIsModalEditarOpen(false)
                        }} type='primary'>Cadastrar nova categoria</Button>
                    </div>

                    <div>
                 <Row className='title-table'>
                     <Col span={9}>
                         <h4>Foto</h4>
                     </Col>
                     <Col span={9}>
                         <h4>Nome</h4>
                     </Col>
                     <Col span={1}>
                         <h4>Ações</h4>
                     </Col>
                 </Row>
                 {categorias?.map((x) => (
                <Row key={x[CategoriaDictionary.ID]} className='title-table'>
                    <Col span={9}>
                    <img style={{maxWidth: 150}} src={`${process.env.REACT_APP_API}${x[CategoriaDictionary.FOTO][CategoriaDictionary.ARQUIVO]}`} alt={x[CategoriaDictionary.NOME]} />
                    </Col>
                    <Col span={9}>
                        <h5>{x[CategoriaDictionary.NOME]}</h5>
                    </Col>
                    <Col className='actions' span={1}>
                    <Dropdown placement="bottomLeft" overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const edit = categorias.find(m => m[CategoriaDictionary.ID] === x[CategoriaDictionary.ID])

                                if(item?.danger) {
                                    try {
                                        await deleteCategoria(x[CategoriaDictionary.ID].toString())
                                        dispatch(getCategorias())
                                    } catch {
                                        toast.error(`Ocorreu um erro ao deletar categoria ${x[CategoriaDictionary.NOME]}`)
                                    }
                                    dispatch(getCategorias())
                                } else {
                                    setEditObj(edit)
                                    setIsModalEditarOpen(true);
                                    setIsModalOpen(true)
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <i style={{color: "black"}} className="ri-file-list-line ri-lg"></i>
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}