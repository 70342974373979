import BannerCarrossel from '../../components/BannerCarrossel';
import Header from '../../components/Header';
import * as S from './styles'
import { Breadcrumb } from 'antd';
import Filtro from './Filtro';
import Conteudo from './Conteudo';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../App';
import { getCategorias, getMateriais, getProdutos, getTamanhos, getTipoPedras } from '../../shared/redux/slices/loja';
import { getProdutosFiltro, getProdutos as getProdutosSelector } from '../../shared/redux/selectors';
import { scrollToTop } from '../../shared/utils/javascript';

const Estoque: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const filtro = useSelector(getProdutosFiltro);
    const produtos = useSelector(getProdutosSelector);

    useEffect(() => {
        if(filtro.length === produtos.length) {
            dispatch(getProdutos())
            dispatch(getCategorias())
            dispatch(getMateriais())
            dispatch(getTipoPedras())
            dispatch(getTamanhos())
        } else {
            dispatch(getCategorias())
            dispatch(getMateriais())
            dispatch(getTipoPedras())
            dispatch(getTamanhos())
        }
    },[])

    useEffect(() => {
        scrollToTop();
      }, []);

    return (
        <S.Container>
            <Header />
            <BannerCarrossel />
            <S.Content>
                <Breadcrumb items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: 'Joias',
                    }
                    ]} />
                <Filtro />
                <Conteudo />
            </S.Content>
        </S.Container>
    )
}

export default Estoque;