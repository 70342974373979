import { Button, Form, Input, message } from 'antd';
import * as S from './styles'
import TextArea from 'antd/es/input/TextArea';
import { Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import { useSelector } from 'react-redux';
import { getLoja } from '../../../shared/redux/selectors';
import { envioEmail, postWhats } from '../../../service/api';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Produto } from '../../../shared/types';

type ContatoProps = {
  tamanho: string;
  produto: Produto;
}

const Contato = ({tamanho, produto} : ContatoProps) => {
    const [form] = Form.useForm();
    const loja = useSelector(getLoja)
    const link = window.location.origin + window.location.pathname + window.location.search;
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    const [ip, setIP] = useState()

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    const onFinish = (values: any) => {
        envioEmail({email: values.email, tipo: "produto", nome: values.nome, telefone: Number(values.telefone.replace(/\D/g, "")), observacao: values.mensagem, cidade: values.cidade, idProduto: Number(params.id)})
        form.resetFields();
        message.success('Mensagem enviada com sucesso!');
      };

      useEffect(() => {
        if(tamanho) {
          form.setFieldsValue({
            mensagem: `Tamanho: ${tamanho}`,
          });
        }
      },[tamanho])


    return (
        <S.Container>
          <h1 style={{marginBottom: '15px'}}>R$ {produto?.preco}</h1>
           <Button onClick={() => {
            if(!ip) {
                return
                } else {
                postWhats({
                    ip: ip
                })
                }
                window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações sobre esta jóia: ${link}.`, '_blank')
           }} className='button-whats'>
            <i style={{color: "white"}} className="ri-whatsapp-line ri-lg" />CHAME A GENTE NO WHATSAPP
           </Button>
           <div className='ou'>
            <hr />
            <h6>ou</h6>
            <hr />
           </div>
           <h3>PREENCHA SEUS DADOS E ENTRAREMOS EM CONTATO</h3>

           <Form
                name="proposta"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                name="nome"
                rules={[{ required: true, message: 'Por favor insira seu nome!' }]}
                >
                <Input placeholder="Nome" />
                </Form.Item>
                
                <Form.Item
                name="telefone"
                rules={[{ required: true, message: 'Por favor insira seu telefone!' }]}
                >
                <Input placeholder="Telefone" />
                </Form.Item>

                <Form.Item
                name="email"
                rules={[{ required: true, message: 'Por favor insira seu e-mail!' }]}
                >
                <Input placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                name="cidade"
                rules={[{ required: true, message: 'Por favor insira sua cidade!' }]}
                >
                <Input placeholder="Cidade" />
                </Form.Item>

                <Form.Item
                name="uf"
                rules={[{ required: true, message: 'Por favor insira seu estado!' }]}
                >
                <Input placeholder="UF" />
                </Form.Item>

                <Form.Item
                name="mensagem"
                rules={[{ required: true, message: 'Por favor insira sua mensagem!' }]}
                >
                <TextArea
                    showCount
                    maxLength={150}
                    style={{ height: 120, resize: 'none' }}
                    placeholder="Digite sua mensagem"
                />
                </Form.Item>

                <Form.Item>
                <Button className='submit-button' type="primary" htmlType="submit">
                    ENVIAR PROPOSTA
                </Button>
                </Form.Item>
            </Form>
        </S.Container>
    )
}

export default Contato;