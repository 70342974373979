import Header from '../../components/Header';
import * as S from './styles'
import { Breadcrumb, Button, Form, Input, Select } from 'antd';
import lojaCotar from '../../assets/images/cotar.png'
import TextArea from 'antd/es/input/TextArea';
import { isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getCategorias, getProdutos } from '../../shared/redux/selectors';
import { Categoria as CategoriaDictionary, Produto as ProdutoDictionary } from '../../shared/utils/dictionary';
import { useEffect, useRef } from 'react';
import { AppDispatch } from '../../App';
import { getProdutos as getProdutosDispatch } from '../../shared/redux/slices/loja';
import { envioEmail } from '../../service/api';

const Cotar: React.FC = () => {

    const [form] = Form.useForm();
    const categoriaSelector = useSelector(getCategorias)
    const produtos = useSelector(getProdutos)
    const dispatch = useDispatch<AppDispatch>()
    const containerRef = useRef<HTMLDivElement>(null);
    const filhoRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
        if (filhoRef.current && containerRef.current) {
          const filhoHeight = filhoRef.current.clientHeight;
          containerRef.current.style.maxHeight = `${filhoHeight + 100}px`;
        }
      }, []);

    useEffect(() => {
        dispatch(getProdutosDispatch())
    },[])

    useEffect(() => {
        scrollToTop();
      }, []);

    const categoria = categoriaSelector.map(item => ({
        value: item[CategoriaDictionary.ID],
        label: item[CategoriaDictionary.NOME]
      }))
    const nomeProdutos = produtos.map(item => ({
        value: item[ProdutoDictionary.ID],
        label: item[CategoriaDictionary.NOME]
      }))

    const onFinish = (values: any) => {
        envioEmail({email: values.email, tipo: "cotar", idProduto: values.exemplo, idCategoria: values.joia, material: values.material, nome: values.nome, observacao: values.observacao, telefone: Number(values.telefone.replace(/\D/g, "")), tipoPedra: values.tipoPedra})
        form.resetFields();
      };

    return (
        <S.Container>
            <Header />
            <S.Content>
                <Breadcrumb className='breadcrumb' items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: 'Peça personalizada',
                    }
                    ]} />
                    {isMobile() ? null : (
                        <div className='titulo'>
                            <h1>QUER COTAR UMA JOIA PERSONALIZADA?</h1>
                            <h2>Complete com o máximo de detalhes possível a ficha cadastral e entraremos em contato em breve.</h2>
                            <hr />
                        </div>
                    )}
                <div className='container' ref={containerRef}>
                    <div className='form' ref={filhoRef}>
                        <div className='titulo-form'>
                            <h3>Cotar uma</h3>
                            <h3 className='amarelo'>joia</h3>
                        </div>
                    <Form
                        name="cotar"
                        onFinish={onFinish}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item
                        name="joia"
                        rules={[{ required: true, message: 'Por favor seleciona uma joia!' }]}
                        >
                            <Select
                            defaultValue="Qual a joia"
                            style={{ width: "100%" }}
                            options={categoria}
                        />
                        </Form.Item>
                        
                        <Form.Item
                        name="material"
                        rules={[{ required: true, message: 'Por favor insira um material!' }]}
                        >
                            <Input placeholder='Material' />
                        </Form.Item>

                        <Form.Item
                        name="tipoPedra"
                        rules={[{ required: true, message: 'Por favor insira o tipo da pedra!' }]}
                        >
                            <Input placeholder='Tipo da pedra' />
                        </Form.Item>

                        <Form.Item
                        name="exemplo"
                        >
                            <Select
                            defaultValue="Peça de exemplo"
                            style={{ width: "100%" }}
                            options={nomeProdutos}
                        />
                        </Form.Item>

                        <Form.Item
                        name="nome"
                        rules={[{ required: true, message: 'Por favor insira seu nome!' }]}
                        >
                            <Input placeholder='Seu nome' />
                        </Form.Item>

                        <Form.Item
                        name="telefone"
                        rules={[{ required: true, message: 'Por favor insira seu telefone!' }]}
                        >
                            <Input placeholder='Seu telefone' />
                        </Form.Item>

                        <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Por favor insira o seu e-mail!' }]}
                        >
                            <Input placeholder='Seu e-mail' />
                        </Form.Item>

                        <Form.Item
                        name="observacao"
                        >
                            <TextArea
                                showCount
                                maxLength={500}
                                style={{ height: 120, resize: 'none' }}
                                placeholder="Observações"
                                />
                        </Form.Item>

                        <Form.Item>
                            <div className='buttons'>
                                <Button onClick={()=> {
                                    form.resetFields();
                                }} className='limpar' type="primary" htmlType="button">
                                    Limpar
                                </Button>
                                <Button className='enviar' type="primary" htmlType="submit">
                                    Enviar
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    </div>
                    {isMobile() ? null : (
                        <div>
                            <img src={lojaCotar} alt="Imagem loja" />
                        </div>
                    )}
                </div>
            </S.Content>
        </S.Container>
    )
}

export default Cotar;