import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: left;
    position: relative;

    span {
        position: relative;
        display: inline-block;
        background-color: #fafafa;
    }

    .hover-info {
        display: block;
        position: absolute;
        width: calc(100% + 2px);
        top: 100%;
        left: -1px;
        background-color: #fafafa;
        border: 1px solid #D6BF91;
        border-top: none;
        border-radius: 0 0 10px 10px;
        padding: 20px;
        transform: translateY(0px);
    }
    .hover-off {
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }
    .mouseHover {
        border-top: 1px solid #D6BF91;
        border-left: 1px solid #D6BF91;
        border-right: 1px solid #D6BF91;
        border-radius: 10px 10px 0 0;
        z-index: 999;
        display: block;
        >img {
            padding: 20px;
        }
    }
    img {
        transition: 100ms linear;
        max-width: calc(90vw / 5);
        max-height: calc(90vw / 5);
        width: calc(90vw / 5);
        height: calc(90vw / 5);
        object-fit: cover;
        cursor: pointer;
        border-radius: 10px;
        @media (max-width: 1672px) {
            width: calc(90vw / 3);
            height: calc(90vw / 3);
            max-width: 100%;
            max-height: auto;
        }
        @media (max-width: 1039px) {
            width: calc(90vw / 2);
            height: calc(90vw / 2);
            max-width: 100%;
            max-height: auto;
        }
    }
    h3 {
        margin-top: -40px;
        font-weight: 500;
        font-size: 15px;
        font-variant: all-small-caps;
        line-height: 18px;
    }
    hr {
        width: 100%;
        margin-top: 15px;
    }
    .detalhes {
        margin-top: 10px;
        text-decoration-line: underline;
        cursor: pointer;
    }

    @media (max-width: 991px) {

        .hover-info {
        display: block;
        position: unset;
        width: calc(100% + 2px);
        top: 100%;
        left: -1px;
        background-color: #fafafa;
        border: none;
        border-radius: 0 0 10px 10px;
        padding: 0px;
        transform: translateY(0px);
        }
        h3 {
        margin-top: -10px;
        font-weight: 500;
        font-size: 13px;
        font-variant: all-small-caps;
        line-height: 12px;
        max-height: 25px;
        overflow: hidden;
        }
        span {
            width: calc(90vw / 2);
            height: calc(90vw / 2);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
        }
        .bolinha {
            width: 5px;
            height: 5px;
            border: 0.5px solid #024F68;
            border-radius: 100px;
        }
        .grid-bolinhas {
            display: flex;
            justify-content: center;
            gap: 10px;
        }
        .index-bolinha {
            width: 5px;
            height: 5px;
            border: 0.5px solid #024F68;
            border-radius: 100px;
            background-color: #024F68;;
        }
    }
`