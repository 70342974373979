import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LojaState } from "../../types";
import { Produto as ProdutoDictionary, Loja as LojaDictionary} from "../../utils/dictionary";
import { 
  getProdutos as getProdutosService,
  getCategorias as getCategoriasService,
  getTamanhos as getTamanhosService,
  getTipoPedras as getTipoPedrasService,
  getMateriais as getMateriaisService,
  getProduto as getProdutoService,
  getCampanhas as getCampanhasService,
  getBanners as getBannersService,
  getLoja as getLojaService,
  } from "../../../service/api"

import { 
  getEmailsRecebidosNewsLetter as getEmailsRecebidosNewsLetterService,
  getEmailsRecebidos as getEmailsRecebidosService, 
  getVisitas as getVisitasService,
  getWhatsCliques as getWhatsCliquesService,
  } from "../../../service/apiAdmin"

const initialState: LojaState = {
  loja: {
    [LojaDictionary.NOME]: "",
    [LojaDictionary.HORARIOS]: "",
    [LojaDictionary.INSTA]: "",
    [LojaDictionary.FB]: "",
    [LojaDictionary.WHATS]: "",
    [LojaDictionary.YB]: "",
    [LojaDictionary.SOBRE]: "",
    [LojaDictionary.CIDADE]: "",
    [LojaDictionary.BAIRRO]: "",
    [LojaDictionary.RUA]: "",
    [LojaDictionary
      .COMPLEMENTO]: "",
    [LojaDictionary.CEP]: "",
    [LojaDictionary.TELEFONES]: [{
      [LojaDictionary.ID_TELEFONE]: "",
      [LojaDictionary.NOME_TELEFONE]: "",
      [LojaDictionary.TELEFONE_WHATS]: 0,
    }],
    [LojaDictionary.EMAILS]: [{
      [LojaDictionary.ID_EMAIL]: "",
      [LojaDictionary.EMAIL]: "",
      [LojaDictionary.NOME_EMAIL]: "",
    }]
  },
  produtos: [],
  produtosFiltro: [],
  produto: {
    [ProdutoDictionary.ID]: 0,
    [ProdutoDictionary.NOME]: "",
    [ProdutoDictionary.CATEGORIA]: {
      idCategoria: 0,
      nome: ""
    },
    [ProdutoDictionary.MATERIAL]: {
      [ProdutoDictionary.NOME_MATERIAL]: ""
    },
    [ProdutoDictionary.TIPO_PEDRA]: {
      nome: ""
    },
    [ProdutoDictionary.TAMANHO]: "",
    [ProdutoDictionary.DESCRICAO]: "",
    [ProdutoDictionary.OBSERVACAO]: "",
    [ProdutoDictionary.FOTOS]: [{
      arquivo: ""
    }],
    [ProdutoDictionary.CAMPANHA]: {
      [ProdutoDictionary.NOME_CAMPANHA]: ""
    },
    [ProdutoDictionary.CLIQUES]: 0,
    [ProdutoDictionary.SITUACAO]: 0,
    [ProdutoDictionary.PRECO]: ""
  },
  filtro: {
    select:{
      categoria: "",
      material: "",
      tipoPedra: "",
      tamanho: ""
    },
    populate: {
      categoria: [],
      tamanho: [],
      tipoPedra: [],
      material: [],
    }
  } ,
  categorias: [],
  tamanhos: [],
  tipoPedras: [],
  materiais: [],
  campanhas: [],
  banners: [],
  emails: {
    contato: [],
    cotar: [],
    produto: [],
    newsLetter: []
  },
  visitas: [],
  whats: [],
};

const lojaSlice = createSlice({
  name: "lojaSlice",
  initialState,
  reducers: {
    resetProduto: (state: LojaState) => {
      state.produto = initialState.produto;
    },
    filtroBusca: (state: LojaState, action: PayloadAction<string>) => {
      const retorno = JSON.parse(JSON.stringify(state.produtos)).filter((x: { nome: string; }) => x.nome.toLowerCase().includes(action.payload.toLowerCase()));
      state.produtosFiltro = retorno;
    },
    oderBy: (state: LojaState, action: PayloadAction<number>) => {
      if(action.payload === 1) {
        state.produtosFiltro = state.produtosFiltro.slice().sort((a,b) => a.quantidadeCliques - b.quantidadeCliques);
      }
      if(action.payload === 2) {
        state.produtosFiltro = state.produtosFiltro.slice().sort((a,b) => a.nome.localeCompare(b.nome));
      }
      if(action.payload === 3) {
        state.produtosFiltro = state.produtosFiltro.slice().sort((a,b) => b.idProduto - a.idProduto);
      }
    },
    clearSelect: (state: LojaState) => {
      state.filtro.select.categoria = "";
      state.filtro.select.material = "";
      state.filtro.select.tipoPedra = "";
      state.filtro.select.tamanho = "";
      state.produtosFiltro = state.produtos
    },
    filtroCampanha: (state: LojaState, action: PayloadAction<string>) => {
      state.produtosFiltro = state.produtos.filter(x => x.campanha?.nome === action.payload)
    },
    setSelectFiltro: (state: LojaState, action: PayloadAction<{valor: string, tipo: string}>) => {
      if(action.payload.tipo === "categoria") {
        state.filtro.select.categoria = action.payload.valor;
        state.filtro.select.material = "";
        state.filtro.select.tipoPedra = "";
        state.filtro.select.tamanho = "";
      }
      if(action.payload.tipo === "material") {
        state.filtro.select.material = action.payload.valor;
        state.filtro.select.tipoPedra = "";
        state.filtro.select.tamanho = "";
      }
      if(action.payload.tipo === "tipoPedra") {
        state.filtro.select.tipoPedra = action.payload.valor;
        state.filtro.select.tamanho = "";
      }
      if(action.payload.tipo === "tamanho") {
        state.filtro.select.tamanho = action.payload.valor;
      }

      // Filtrando estoque
      if(state.filtro.select.categoria !== "") {
        const produtosFilter = state.produtos.filter(x => x.categoria.nome === state.filtro.select.categoria)
        state.produtosFiltro = produtosFilter

        const material = state.produtosFiltro.map((x, i) => {
          return {idMaterial: i, nome: x.material.nome}
        })
        state.filtro.populate.material = material.filter((obj, index, self) => {
          return index === self.findIndex((o) => o.nome === obj.nome);
        });
  
        const tipoPedra = state.produtosFiltro.map((x, i) => {
          return {idTipoPedra: i, nome: x.tipoPedra.nome}
        })
        state.filtro.populate.tipoPedra = tipoPedra.filter((obj, index, self) => {
          return index === self.findIndex((o) => o.nome === obj.nome);
        });
  
        const tamanho = state.produtosFiltro.map((x, i) => {
          return x.tamanhos
        })
        const tamanhosArray = tamanho.map((x, i) => {
          return x?.split(" | ").map((y, i) => {
            return y
        })
        }).flat(2)
        const tamanhosNotRepeat = tamanhosArray.filter((obj, index, self) => {
          return index === self.findIndex((o) => o === obj);
        });
        state.filtro.populate.tamanho = tamanhosNotRepeat.map((x,i) => {
          return {idTamanho: i, tamanho: x}
        }).sort((a, b) => {
          const tamanhoA = parseInt(a.tamanho); // Tentar converter para número
          const tamanhoB = parseInt(b.tamanho); // Tentar converter para número
        
          // Se os dois tamanhos não forem números, deixe-os na mesma ordem
          if (isNaN(tamanhoA) && isNaN(tamanhoB)) {
            return 0;
          }
        
          // Colocar os tamanhos não numéricos no final do array
          if (isNaN(tamanhoA)) {
            return 1;
          }
        
          if (isNaN(tamanhoB)) {
            return -1;
          }
        
          // Ordenar os tamanhos numéricos normalmente
          return tamanhoA - tamanhoB;
        });
      } else {
        state.produtosFiltro = state.produtos
      }
      if(state.filtro.select.material !== "") {
        const filtroMaterial = state.produtosFiltro.filter(x => x.material.nome === state.filtro.select.material)
        state.produtosFiltro = filtroMaterial

        const tipoPedra = state.produtosFiltro.map((x, i) => {
          return {idTipoPedra: i, nome: x.tipoPedra.nome}
        })
        state.filtro.populate.tipoPedra = tipoPedra.filter((obj, index, self) => {
          return index === self.findIndex((o) => o.nome === obj.nome);
        });
  
        const tamanho = state.produtosFiltro.map((x, i) => {
          return x.tamanhos
        })
        const tamanhosArray = tamanho.map((x, i) => {
          return x?.split(" | ").map((y, i) => {
            return y
        })
        }).flat(2)
        const tamanhosNotRepeat = tamanhosArray.filter((obj, index, self) => {
          return index === self.findIndex((o) => o === obj);
        });
        state.filtro.populate.tamanho = tamanhosNotRepeat.map((x,i) => {
          return {idTamanho: i, tamanho: x}
        }).sort((a, b) => {
          const tamanhoA = parseInt(a.tamanho); // Tentar converter para número
          const tamanhoB = parseInt(b.tamanho); // Tentar converter para número
        
          // Se os dois tamanhos não forem números, deixe-os na mesma ordem
          if (isNaN(tamanhoA) && isNaN(tamanhoB)) {
            return 0;
          }
        
          // Colocar os tamanhos não numéricos no final do array
          if (isNaN(tamanhoA)) {
            return 1;
          }
        
          if (isNaN(tamanhoB)) {
            return -1;
          }
        
          // Ordenar os tamanhos numéricos normalmente
          return tamanhoA - tamanhoB;
        });
      }
      if(state.filtro.select.tipoPedra !== "") {
        const filtroTipoPedra = state.produtosFiltro.filter(x => x.tipoPedra.nome === state.filtro.select.tipoPedra)
        state.produtosFiltro = filtroTipoPedra

        const tamanho = state.produtosFiltro.map((x, i) => {
          return x.tamanhos
        })
        const tamanhosArray = tamanho.map((x, i) => {
          return x?.split(" | ").map((y, i) => {
            return y
        })
        }).flat(2)
        const tamanhosNotRepeat = tamanhosArray.filter((obj, index, self) => {
          return index === self.findIndex((o) => o === obj);
        });
        state.filtro.populate.tamanho = tamanhosNotRepeat.map((x,i) => {
          return {idTamanho: i, tamanho: x}
        })
      }
      if(state.filtro.select.tamanho !== "") {
        const filtroTamanho = state.produtosFiltro.filter(x => x.tamanhos.includes(state.filtro.select.tamanho))
        state.produtosFiltro = filtroTamanho
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getBanners.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.banners = action.payload.response;
        }
      );
    builder.addCase(
      getLoja.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loja = action.payload.response;
        }
      );
    builder.addCase(
      getProdutos.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.produtos = action.payload.response;
          state.produtosFiltro = action.payload.response;
        }
      );
    builder.addCase(
      getProduto.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.produto = action.payload.response[0];
        }
      );
    builder.addCase(
      getCategorias.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.categorias = action.payload.response;

          const categorias = state.produtos.map((x, i) => {
            return {idCategoria: i, nome: x.categoria.nome}
          })
          state.filtro.populate.categoria = categorias.filter((obj, index, self) => {
            return index === self.findIndex((o) => o.nome === obj.nome);
          });
        }
      );
    builder.addCase(
      getTamanhos.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.tamanhos = action.payload.response;
          
          const tamanho = state.produtosFiltro.map((x, i) => {
            return x.tamanhos
          })
          const tamanhosArray = tamanho.map((x, i) => {
            return x?.split(" | ").map((y, i) => {
              return y
          })
          }).flat(2)
          const tamanhosNotRepeat = tamanhosArray.filter((obj, index, self) => {
            return index === self.findIndex((o) => o === obj);
          });
          state.filtro.populate.tamanho = tamanhosNotRepeat.map((x,i) => {
            return {idTamanho: i, tamanho: x}
          }).sort((a, b) => {
            const tamanhoA = parseInt(a.tamanho); // Tentar converter para número
            const tamanhoB = parseInt(b.tamanho); // Tentar converter para número
          
            // Se os dois tamanhos não forem números, deixe-os na mesma ordem
            if (isNaN(tamanhoA) && isNaN(tamanhoB)) {
              return 0;
            }
          
            // Colocar os tamanhos não numéricos no final do array
            if (isNaN(tamanhoA)) {
              return 1;
            }
          
            if (isNaN(tamanhoB)) {
              return -1;
            }
          
            // Ordenar os tamanhos numéricos normalmente
            return tamanhoA - tamanhoB;
          });
        }
      );
    builder.addCase(
      getTipoPedras.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.tipoPedras = action.payload.response;

          const tipoPedra = state.produtosFiltro.map((x, i) => {
            return {idTipoPedra: i, nome: x.tipoPedra.nome}
          })
          state.filtro.populate.tipoPedra = tipoPedra.filter((obj, index, self) => {
            return index === self.findIndex((o) => o.nome === obj.nome);
          });
        }
      );
    builder.addCase(
      getMateriais.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.materiais = action.payload.response;

          const material = state.produtosFiltro.map((x, i) => {
            return {idMaterial: i, nome: x.material.nome}
          })
          state.filtro.populate.material = material.filter((obj, index, self) => {
            return index === self.findIndex((o) => o.nome === obj.nome);
          });
        }
      );
    builder.addCase(
      getCampanhas.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.campanhas = action.payload.response;
        }
      );
    builder.addCase(
      getEmailsRecebidos.fulfilled,
        (state, action: PayloadAction<any>) => {
          const emails = action.payload.response;
          state.emails.contato = emails.filter((x: { tipo: string; }) => x.tipo === "contato")
          state.emails.cotar = emails.filter((x: { tipo: string; }) => x.tipo === "cotar")
          state.emails.produto = emails.filter((x: { tipo: string; }) => x.tipo === "produto")
        }
      );
    builder.addCase(
      getEmailsRecebidosNewsLetter.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.emails.newsLetter = action.payload.response;
        }
      );
    builder.addCase(
      getVisitas.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.visitas = action.payload.response;
        }
      );
    builder.addCase(
      getCliquesWhats.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.whats = action.payload.response;
        }
      );
  }
});

export const getProdutos = createAsyncThunk<any[], void>(
  "loja/getProdutos",
  async () => {
      const response = await getProdutosService();
      return response?.data; 
  }
);

export const getProduto = createAsyncThunk<any[], string>(
  "loja/getProduto",
  async (data) => {
      const response = await getProdutoService(data);
      return response?.data; 
  }
);

export const getCategorias = createAsyncThunk<any[], void>(
  "loja/getCategorias",
  async () => {
      const response = await getCategoriasService();
      return response?.data; 
  }
);

export const getBanners = createAsyncThunk<any[], void>(
  "loja/getBanner",
  async () => {
      const response = await getBannersService();
      return response?.data; 
  }
);

export const getTamanhos = createAsyncThunk<any[], void>(
  "loja/getTamanhos",
  async () => {
      const response = await getTamanhosService();
      return response?.data; 
  }
);

export const getTipoPedras = createAsyncThunk<any[], void>(
  "loja/getTipoPedras",
  async () => {
      const response = await getTipoPedrasService();
      return response?.data; 
  }
);

export const getMateriais = createAsyncThunk<any[], void>(
  "loja/getMateriais",
  async () => {
      const response = await getMateriaisService();
      return response?.data; 
  }
);

export const getCampanhas = createAsyncThunk<any[], void>(
  "loja/getCampanhas",
  async () => {
      const response = await getCampanhasService();
      return response?.data; 
  }
);

export const getLoja = createAsyncThunk<any[], void>(
  "loja/getLoja",
  async () => {
      const response = await getLojaService();
      return response?.data; 
  }
);

export const getEmailsRecebidos = createAsyncThunk<any[], void>(
  "loja/getEmailsRecebidos",
  async () => {
      const response = await getEmailsRecebidosService();
      return response?.data; 
  }
);

export const getEmailsRecebidosNewsLetter = createAsyncThunk<any[], void>(
  "loja/getEmailsNewsLetter",
  async () => {
      const response = await getEmailsRecebidosNewsLetterService();
      return response?.data; 
  }
);

export const getVisitas = createAsyncThunk<any[], void>(
  "loja/getVisitas",
  async () => {
      const response = await getVisitasService();
      return response?.data; 
  }
);

export const getCliquesWhats = createAsyncThunk<any[], void>(
  "loja/getWhats",
  async () => {
      const response = await getWhatsCliquesService();
      return response?.data; 
  }
);

export const { resetProduto, setSelectFiltro, clearSelect, oderBy, filtroCampanha, filtroBusca } = lojaSlice.actions;
export default lojaSlice.reducer;
