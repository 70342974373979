import { AsideAdmin } from '../AsideAdmin'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { useEffect, useState } from 'react'
import { getEmailsRecebidos as getEmailsRecebidosSelector } from '../../../shared/redux/selectors'
import { getEmailsRecebidosNewsLetter, getEmailsRecebidos } from '../../../shared/redux/slices/loja'

export const Emails = () => {
    const dispatch = useDispatch<AppDispatch>()
    const emails = useSelector(getEmailsRecebidosSelector)
    const [listFilter, setListFilter] = useState<any>()

    useEffect(()=>{
        dispatch(getEmailsRecebidosNewsLetter())
        dispatch(getEmailsRecebidos())
    },[])

    useEffect(() => {
        setListFilter(emails?.contato?.slice().reverse())
    },[emails])

    const columnsNewsLetter = [
      {
        title: 'Id',
        dataIndex: 'idNewsletter',
        key: 'idNewsletter',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Data de cadastro',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => {
          return text?.substring(0, 10)
        }
      },
    ];

      const columns = [
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
        {
          title: 'Telefone',
          dataIndex: 'telefone',
          key: 'telefone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Mensagem',
          dataIndex: 'observacao',
          key: 'observacao',
        },
        {
          title: 'Data',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (text: string) => {
            return text?.substring(0, 10)
          }
        },
      ];

      const handleChangeMes = (value: 'contato' | 'produto' | 'newsLetter' | 'cotar') => {
        setListFilter(emails[value])
      };
   
    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
              <i className="ri-mail-line ri-3x"></i>
                <h1>Emails</h1>
            </div>
            <h4>Filtrar registros pela categoria de email: {listFilter?.length}</h4>
            <div style={{display: "flex", flexDirection: "row", gap: 20}}>
                <Select
                    defaultValue="contato"
                    style={{ width: 300 }}
                    onChange={handleChangeMes}
                    options={[
                        { value: 'contato', label: 'Contato' },
                        { value: 'produto', label: 'Produto' },
                        { value: 'cotar', label: 'Cotar' },
                    ]}
                />
            </div>
            <Table style={{overflowX: "auto"}} dataSource={listFilter} columns={columns} />
            <div className='title'>
                <i className="ri-mail-line ri-3x"></i>
                <h1>Emails de news letter</h1>
                <Table style={{overflowX: "auto"}} dataSource={emails?.newsLetter} columns={columnsNewsLetter} />
            </div>
        </S.Container>
    )
}