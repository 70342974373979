import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(219, 219, 219, 0.25);

    span {
        align-items: center;
        .ant-btn {
            display: none;
        }
    }
    .search-box {
        display: flex;
        align-items: center;
    }
    .search-box-colored {
        display: flex;
        align-items: center;
        background-color: #fafafa;
        border-radius: 5px;
        padding-right: 10px;
    }
    .input-search {
        width: 100%;
        padding: 10px 20px;
        font-family: Montserrat;
        ::placeholder {
            font-family: Montserrat;
        }
    }
    .ant-input, .ant-btn  {
        border: none;
        background-color: #FAFAFA;
        border-radius: 3px;
    }
    .ant-input-wrapper {
        gap: 0;
    }
`
export const ContainerHeader = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    @media screen {
     padding: 0 15px ;
    }
    i {
        cursor: pointer;
    }
    a {
        text-decoration: none; /* Remove o underline */
        color: inherit;
    }
    span {
        display: flex;
        gap: 20px;
    }
    img {
        max-height: 92px;
        cursor: pointer;
    }
`
export const ContainerCategorias = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px 80px;
    border-top: 1px solid #F6EFEF;
    width: 90%;

    .campanha {
        font-weight: 500;
        color: red;
    }
    h4 {
        cursor: pointer;
        font-weight: 400;
    }
`