//================================================
// Envios para o back end

//================================================
// Retornos do back end

export enum Banner {
  ID = "idBanner",
  LINK = "link",
  FOTO = "imagem",
  ARQUIVO = "arquivo",
  POSICAO = "posicao",
  MOBILE = "dispositivo",
  DESCRICAO = "descricao",
}

export enum Categoria {
  ID = "idCategoria",
  NOME = "nome",
  FOTO = "imagem",
  ARQUIVO = "arquivo"
}

export enum Campanha {
  ID = "idCampanha",
  NOME = "nome",
  SITUACAO = "situacao",
  DATA_INICIO = "dataInicio",
  DATA_FIM = "dataFim",
}

export enum EmailsRecebidos {
  ID = "idEmailRecebido",
  TIPO = "tipo",
  NOME = "nome",
  TELEFONE = "telefone",
  EMAIL = "email",
  CIDADE = "cidade",
  MATERIAL = "material",
  TIPO_PEDRA = "tipoPedra",
  OBS = "observacao",
  ID_CATEGORIA = "idCategoria",
  ID_PRODUTO = "idProduto",
  CATEGORIA = "categoria",
  PRODUTO = "produto",

  // News Letter
  ID_NEWS_LETTER = "idNewsletter",
}

export enum Produto {
  ID = "idProduto",
  NOME = "nome",
  PRECO = "preco",
  CATEGORIA = "categoria",
  NOME_CATEGORIA = "nome",
  ID_CATEGORIA = "idCategoria",
  TIPO_PEDRA = "tipoPedra",
  NOME_TIPO_PEDRA = "nome",
  FOTOS = "imagens",
  ARQUIVO = "arquivo",
  CAMPANHA = "campanha",
  NOME_CAMPANHA = "nome",
  MATERIAL = "material", 
  NOME_MATERIAL = "nome", 
  TAMANHO = "tamanhos",
  DESCRICAO = "descricao",
  OBSERVACAO = "observacao",
  CLIQUES = "quantidadeCliques",
  SITUACAO = "situacao",
}

export enum Visita {
  ID = "idVisita",
  DATA = "data",
  IP = "ip",
}

export enum Whats {
  ID = "idVisita",
  DATA = "data",
  IP = "ip",
}

export enum Loja {
  NOME = "nome",
  HORARIOS = "horarioAtendimento",
  INSTA = "instagram",
  FB = "facebook",
  WHATS = "whatsapp",
  YB = "youtube",
  SOBRE = "sobre",
  CIDADE = "cidade",
  BAIRRO = "bairro",
  RUA = "rua",
  COMPLEMENTO = "complemento",
  CEP = "cep",
  TELEFONES = "telefones",
  ID_TELEFONE = "idTelefone",
  NOME_TELEFONE = "nome",
  TELEFONE_WHATS = "whatsapp",
  EMAILS = "emails",
  ID_EMAIL = "idEmail",
  EMAIL = "email",
  NOME_EMAIL = "nome",
}

export enum Tamanho {
  ID = "idTamanho",
  TAMANHO = "tamanho",
}

export enum TipoPedra {
  ID = "idTipoPedra",
  NOME = "nome",
}

export enum Material {
  NOME = "nome",
  ID = "idMaterial",
}

//================================================
// Helpers
