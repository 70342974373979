import { useRef, useState } from 'react'
import { Produto } from '../../shared/types'
import { Produto as ProdutoDictionary } from '../../shared/utils/dictionary'
import * as S from './styles'
import { isMobile } from '../../shared/utils/javascript'

type TypeCardProduto = {
    produto: Produto
    estoque?: boolean
    interesse?: boolean
}

const CardProduto = ({produto, estoque, interesse} : TypeCardProduto) => {

    const [mouseHover, setMouseHover] = useState(false)
    const [index, setIndex] = useState(0)
    const imageRef = useRef(null);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [functionCalled, setFunctionCalled] = useState(false);

    const redirect = () => {
        const data = {
            id: String(produto[ProdutoDictionary.ID])
        } 
        const url = new URLSearchParams(data).toString()
        window.location.href = `/produto/?${url}`
    }

    const handleTouchStart = (e: React.TouchEvent<HTMLImageElement>) => {
        if (e.touches.length > 0) {
            setTouchStartX(e.touches[0].clientX);
            setFunctionCalled(false);
          }
      };
    
      const handleTouchMove = (e: React.TouchEvent<HTMLImageElement>) => {
        if (touchStartX !== null && e.touches.length > 0) {
            const touchMoveX = e.touches[0].clientX;
            const deltaX = touchMoveX - touchStartX;
      
            if (deltaX > 50 && !functionCalled) {
              // Arrastar para a direita
              if(index === 0) {
                setFunctionCalled(true);
              } else {
                setIndex(index -1)
              }
              setFunctionCalled(true);
            } else if (deltaX < -50 && !functionCalled) {
              if(produto?.[ProdutoDictionary.FOTOS].length - 1 === index) {
                setFunctionCalled(true);
              } else {
                setIndex(index + 1)
              }
              setFunctionCalled(true);
            }
          }
      };
    
      const handleTouchEnd = () => {
        setTouchStartX(null);
      };
    
    return (
        <S.Container onMouseOver={() => {!isMobile() ? setMouseHover(true) : setMouseHover(false)}} onMouseLeave={() => {setMouseHover(false)}}>
            <span className={mouseHover ? 'mouseHover' : 'hover-off'}>
            <img onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} ref={imageRef} onClick={redirect} src={`${process.env.REACT_APP_API}${produto?.[ProdutoDictionary.FOTOS]?.[index]?.[ProdutoDictionary.ARQUIVO]}`} alt={produto?.[ProdutoDictionary.NOME]} />
            {estoque && isMobile() ? (<div className='grid-bolinhas'>{produto[ProdutoDictionary.FOTOS].map((x, i) => (
                <span onClick={() => {
                    setIndex(i)
                }} className={index === i ? "index-bolinha" : "bolinha"} key={x[ProdutoDictionary.ARQUIVO]} />
            ))}</div>) : null}
            {mouseHover || (isMobile() && !estoque) ? (
                <div className='hover-info'>
                  {!interesse ? (<h3>{produto?.[ProdutoDictionary.NOME]} {produto?.[ProdutoDictionary.DESCRICAO]}</h3>) : null}
                    {isMobile() ? null : (
                        <>
                            <hr />
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                              <h3 style={{marginTop: '10px'}}>R$ {produto?.[ProdutoDictionary.PRECO]}</h3>
                              <h3 onClick={redirect} className='detalhes'>Ver detalhes</h3>
                            </div>
                        </>
                    )}
                </div>
            ) : null}
            </span>
        </S.Container>
    )
}

export default CardProduto;