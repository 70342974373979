import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import { Line } from "react-chartjs-2";
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { useEffect, useState } from 'react'
import { AsideAdmin } from '../AsideAdmin'

import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getVisitas } from '../../../shared/redux/slices/loja'
import { getVisitas as getVisitasSelector } from '../../../shared/redux/selectors'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const Visitas = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [labelChartDias, setLabelChartDias] = useState<any>([])
    const [dataChartDias, setDataChartDias] = useState<any>([])
    const [labelChartMeses, setLabelChartMeses] = useState<any>([])
    const [dataChartMeses, setDataChartMeses] = useState<any>([])
    const [labelChartAno, setLabelChartAno] = useState<any>([])
    const [dataChartAno, setDataChartAno] = useState<any>([])
    const visitas = useSelector(getVisitasSelector)
    const login = Cookies.get('auth');
    const navigate = useNavigate()

    useEffect(()=> {
        if(!login) {
            navigate('/admin')
        }
        dispatch(getVisitas())
    },[login])

    const filterDataDias = visitas.filter(obj => {
        const currentDate = new Date();
        const objDate = new Date(obj.data);
        const differenceInTime = currentDate.getTime() - objDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 15;
      });
      
      const filteredDataMeses = visitas.filter(obj => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const objDate = new Date(obj.data);
        const objYear = objDate.getFullYear();
        const objMonth = objDate.getMonth() + 1;
      
        return objYear === currentYear && objMonth >= currentMonth - 6 && objMonth <= currentMonth;
      });

      
      const filteredDataAno = visitas.filter(obj => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const objDate = new Date(obj.data);
        const objYear = objDate.getFullYear();
      
        return objYear >= currentYear - 6 && objYear <= currentYear;
      });

    useEffect(() => {
        //Dias
        const accessCountsDias : { [date: string]: number } = {};
        filterDataDias.forEach(obj => {
        const date = obj.data;
        accessCountsDias[date] = accessCountsDias[date] ? accessCountsDias[date] + 1 : 1;
        });
        setLabelChartDias(Object.keys(accessCountsDias))
        setDataChartDias(Object.values(accessCountsDias))

        //Meses
        const accessCountsMeses : { [date: string]: number } = {};
        filteredDataMeses.forEach(objDate => {
          const monthYear = `${new Date(objDate.data).getMonth() + 1}-${new Date(objDate.data).getFullYear()}`;
        
          if (accessCountsMeses[monthYear]) {
            accessCountsMeses[monthYear]++;
          } else {
            accessCountsMeses[monthYear] = 1;
          }
        });
        setLabelChartMeses(Object.keys(accessCountsMeses))
        setDataChartMeses(Object.values(accessCountsMeses))

        //Ano
        const accessCountsAno : { [date: string]: number } = {};
        filteredDataAno.forEach(objDate => {
          const year = new Date(objDate.data).getFullYear();

          if (accessCountsAno[year]) {
            accessCountsAno[year]++;
          } else {
            accessCountsAno[year] = 1;
          }
        });
        setLabelChartAno(Object.keys(accessCountsAno))
        setDataChartAno(Object.values(accessCountsAno))

    },[visitas])

    const dataDias = {
        labels: labelChartDias,
        datasets: [
          {
            label: "Acessos",
            data: dataChartDias,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    const dataMeses = {
        labels: labelChartMeses,
        datasets: [
          {
            label: "Acessos",
            data: dataChartMeses,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    const dataAno = {
        labels: labelChartAno,
        datasets: [
          {
            label: "Acessos",
            data: dataChartAno,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    return (
        <S.Container>

                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title'>
                        <i className="ri-home-2-line ri-3x"></i>
                        <h1>Visitas</h1>
                    </div>
                    <div className='title-bar'>
                        <i className="ri-line-chart-line ri-2x"></i>
                        <h3>Visitas dos últimos dias</h3>
                    </div>
                    <div className='inside-bar'>
                        <Line style={{maxHeight: 200}} data={dataDias}/>
                    </div>
                    <div className='title-bar'>
                        <i className="ri-line-chart-line ri-2x"></i>
                        <h3>Visitas dos últimos meses</h3>
                    </div>
                    <div className='inside-bar'>
                        <Line style={{maxHeight: 200}} data={dataMeses}/>
                    </div>
                    <div className='title-bar'>
                        <i className="ri-line-chart-line ri-2x"></i>
                        <h3>Visitas dos últimos anos</h3>
                    </div>
                    <div className='inside-bar'>
                        <Line style={{maxHeight: 200}} data={dataAno}/>
                    </div>
        </S.Container>
    )
}