import * as S from './styles'
import { Dropdown, MenuProps, Space } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export const HeaderAdmin = () => {
    const navigate = useNavigate()

    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <div onClick={() => {
              Cookies.remove('auth');
              Cookies.remove('usuario');
              Cookies.remove('senha');
              navigate("/")
            }}>
              <a style={{color: "red"}} rel="noopener noreferrer" href="/">
                Sair
              </a>
            </div>
          ),
        }
      ];
      
    return (
        <S.Container>
            <a href='/admin'>Joalheria Carvalho</a>
            <Dropdown overlayStyle={{zIndex: 9999999}} menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                    <i className="ri-user-line ri-lg"></i>
                    <i className="ri-arrow-drop-down-fill ri-lg"></i>
                </a>
            </Dropdown>
        </S.Container>
    )
}