import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    h3{
        margin-top: 30px;
        font-weight: 500;
        letter-spacing: 0.1em;
        font-variant: all-small-caps;
        font-size: 25px;
    }
    h4 {
        font-weight: 500;
        font-size: 15px;
    }
    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    hr {
        width: 100px;
        border: 1px solid #D6BF91;
        background-color: #D6BF91;
    }
    .mobile-grid {
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        margin-top: 20px;
        gap: 15px;
        padding: 0 15px 30px 15px;
    }
`

export const Carrossel = styled.div`
    padding: 30px 20px;
    max-width: 100%;
    width: 100%;
    max-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
`