import Header from '../../components/Header';
import * as S from './styles'
import { Breadcrumb, Button, Checkbox, Form, Input, message } from 'antd';
import { Loja as LojaDictionary } from '../../shared/utils/dictionary';
import TextArea from 'antd/es/input/TextArea';
import { formatPhoneNumber, isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useSelector } from 'react-redux';
import { getLoja } from '../../shared/redux/selectors';
import { envioEmail, envioEmailNewsLetter, postWhats } from '../../service/api';
import axios from 'axios';
import { useEffect, useState } from 'react';

const Contato: React.FC = () => {

    const loja = useSelector(getLoja)

    const [form] = Form.useForm();
    const [ip, setIP] = useState()

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    useEffect(() => {
        scrollToTop();
      }, []);

    const onFinish = (values: any) => {
        if(values.novidades === 'on') {
            envioEmailNewsLetter({email: values.email})
        }
        envioEmail({email: values.email, tipo: "contato", nome: values.nome, observacao: values.mensagem})
        form.resetFields();
        message.success('Mensagem enviada com sucesso!');
      };

    return (
        <S.Container>
            <Header />
            <S.Content>
                <Breadcrumb items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: 'Contatos',
                    }
                    ]} />
                    <div className='middle-content'>
                        <h2>NOSSOS MEIOS DE CONTATO</h2>
                        <h3>Todos os nossos meios de contato, procure a gente em alguma rede social ou deixe um e-mail para nós.</h3>
                        <hr />
                    {isMobile() ? null : (
                        <div className='info-mapa'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14207.377819345613!2d-48.91572!3d-27.0982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df47994ad4e73b%3A0x95198dbcc561a156!2sCarvalho%20Joalheria%20e%20%C3%93ptica!5e0!3m2!1spt-BR!2sbr!4v1684424525470!5m2!1spt-BR!2sbr" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            <div className='infos'>
                                <h2 style={{marginBottom: "15px", textAlign: "center"}}>INFORMAÇÕES</h2>
                                <h4>ENDEREÇO: {loja[LojaDictionary.RUA]}, {loja[LojaDictionary.BAIRRO]}, {loja[LojaDictionary.CIDADE]} - SC - CEP {loja[LojaDictionary.CEP]}</h4>
                                <h4>EMAIL: {loja[LojaDictionary.EMAILS]?.[0]?.[LojaDictionary.EMAIL]}</h4>
                                <h4>TELEFONE: (+55) {loja[LojaDictionary.TELEFONES]?.find(x => x.whatsapp === 0)?.[LojaDictionary.NOME_TELEFONE]}</h4>
                                <h4>   
                                    <div className='icons'>MIDIAS SOCIAIS:
                                        <i onClick={() => {
                                           window.open(`${loja[LojaDictionary.FB]}`, '_blank')
                                        }} style={{color: "#0057FF"}} className="ri-facebook-fill ri-xl"></i>  
                                        <i onClick={() => {
                                           window.open(`${loja[LojaDictionary.INSTA]}`, '_blank')
                                        }} style={{color: "#FA00FF"}} className="ri-instagram-line ri-xl"></i>      
                                        <i onClick={() => {
                                           window.open(`${loja[LojaDictionary.YB]}`, '_blank')
                                        }} style={{color: "#FF0000"}} className="ri-youtube-fill ri-xl"></i>    
                                    </div>
                                </h4>
                            </div>
                        </div>
                    )}

                        <div className='grid-card'>
                            <div onClick={() => {
                                if(!ip) {
                                    return
                                    } else {
                                    postWhats({
                                        ip: ip
                                    })
                                    }
                                window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações`, '_blank')}
                        } className='card'>
                                <i style={{color: '#024F68'}} className="ri-whatsapp-line ri-3x"></i>
                                <h5>WHATSAPP</h5>
                                <h4>{formatPhoneNumber(loja[LojaDictionary.WHATS])}</h4>
                            </div>
                            <div onClick={() => {
                                window.open(`mailto:${loja[LojaDictionary.EMAILS][0][LojaDictionary.EMAIL]}`, "_blank")
                            }} className='card'>
                                <i style={{color: '#024F68'}} className="ri-mail-line ri-3x"></i>
                                <h5>E-MAIL</h5>
                                <h4>{loja[LojaDictionary.EMAILS]?.[0]?.[LojaDictionary.EMAIL]}</h4>
                            </div>
                            <div onClick={() => {
                                 window.open(`tel:+55${loja[LojaDictionary.TELEFONES].filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[0]?.[LojaDictionary.NOME_TELEFONE].replace(/[( )-]/g,"")}`, "_blank")
                            }} className='card'>
                                <i style={{color: '#024F68'}} className="ri-phone-line ri-3x"></i>
                                <h5>TELEFONE</h5>
                                <h4>{loja[LojaDictionary.TELEFONES]?.filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)[0]?.[LojaDictionary.NOME_TELEFONE]}</h4>
                            </div>
                            <div onClick={() => {
                                 window.open(`tel:+55${loja[LojaDictionary.TELEFONES].filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[1]?.[LojaDictionary.NOME_TELEFONE].replace(/[( )-]/g,"")}`, "_blank")
                            }} className='card'>
                                <i style={{color: '#024F68'}} className="ri-phone-line ri-3x"></i>
                                <h5>TELEFONE</h5>
                                <h4>{loja[LojaDictionary.TELEFONES]?.filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)[1]?.[LojaDictionary.NOME_TELEFONE]}</h4>
                            </div>
                        </div>

                        <div className='form'>
                            <h2>OU NOS ENVIE UM E-MAIL</h2>
                            <Form
                                name="proposta"
                                onFinish={onFinish}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item
                                name="nome"
                                rules={[{ required: true, message: 'Por favor insira seu nome!' }]}
                                >
                                <Input placeholder="Nome" />
                                </Form.Item>

                                <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Por favor insira seu e-mail!' }]}
                                >
                                <Input placeholder="E-mail" />
                                </Form.Item>

                                <Form.Item
                                name="mensagem"
                                rules={[{ required: true, message: 'Por favor insira seu estado!' }]}
                                >
                                <TextArea
                                    showCount
                                    maxLength={150}
                                    style={{ height: 120, resize: 'none' }}
                                    placeholder="Digite sua mensagem"
                                />
                                </Form.Item>

                                <Form.Item
                                name="novidades"
                                >
                                    <span style={{display: "flex", gap: '10px', alignItems: "center"}}>
                                        <Checkbox />
                                        <h6>DESEJO RECEBER NOVIDADES</h6>
                                    </span>
                                </Form.Item>

                                <Form.Item>
                                <Button className='submit-button' type="primary" htmlType="submit">
                                    ENVIAR UMA MENSAGEM
                                </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
            </S.Content>
        </S.Container>
    )
}

export default Contato;