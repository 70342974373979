import CardCategoria from '../../../components/CardCategoria';
import * as S from './styles'
import { useSelector } from 'react-redux';
import { getCategorias } from '../../../shared/redux/selectors';

const Categorias = () => {
    const categorias = useSelector(getCategorias)
   
    return (
        <S.Container>
            <div className='title'>
                <h3>OLHAR POR categoria</h3>
                <h4>Um design excepcional e habilidades artesanais incomparáveis.</h4>
                <hr />
            </div>
            <S.Grid>
                <CardCategoria categoria={categorias[0]} />
                <CardCategoria categoria={categorias[1]} />
                <CardCategoria categoria={categorias[2]} />
                <CardCategoria categoria={categorias[3]} />
                <CardCategoria categoria={categorias[4]} />
                <CardCategoria categoria={categorias[5]} />
            </S.Grid>
        </S.Container>
    )
}

export default Categorias;