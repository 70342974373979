import styled from 'styled-components';

export const Container = styled.div`
    overflow: hidden;
`
export const Content = styled.div`
    padding: 25px 50px;

    .ant-breadcrumb-link {
        font-family: "Montserrat";
        font-size: 13px;
    }

    @media (max-width: 991px) {
        padding: 10px;
    }
`