import axios from "axios";

const apiProducts = axios.create({
  baseURL: `${process.env.REACT_APP_API}/`,
});

export const getCategorias = () => {
  try {
    return apiProducts.get<any>(`/categoria`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getTamanhos = () => {
  try {
    return apiProducts.get<any>(`/tamanho`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getProdutos = () => {
  try {
    return apiProducts.get<any>(`/produto`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getProduto = (data: string) => {
  try {
    return apiProducts.get<any>(`/produto/${data}`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getTipoPedras = () => {
  try {
    return apiProducts.get<any>(`/tipopedra`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getMateriais = () => {
  try {
    return apiProducts.get<any>(`/material`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getCampanhas = () => {
  try {
    return apiProducts.get<any>(`/campanha`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getLoja = () => {
  try {
    return apiProducts.get<any>(`/loja/1`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getBanners = () => {
  try {
    return apiProducts.get<any>(`/banner`);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const envioEmail = (data: any) => {
  try {
    return apiProducts.post<any>(`/emailRecebidos`, data);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const envioEmailNewsLetter = (data: any) => {
  try {
    return apiProducts.post<any>(`/newsletter`, data);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postVisita = (data: any) => {
  try {
    return apiProducts.post<any>(`/registro/visita`, data);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postWhats = (data: any) => {
  try {
    return apiProducts.post<any>(`/registro/whatsapp`, data);
  } catch (error) {
    console.log(error)
    throw error
  }
};