import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { AsideAdmin } from '../AsideAdmin'
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row } from 'antd'
import { getTipoPedras as getTipoPedrasSelector } from '../../../shared/redux/selectors'
import { TipoPedra as TipoPedraDictionary } from '../../../shared/utils/dictionary'
import { getTipoPedras } from '../../../shared/redux/slices/loja'
import { useEffect, useState } from 'react'
import { deletePedra, editPedra, postPedra } from '../../../service/apiAdmin'
import { toast } from 'react-toastify'

export const Pedra = () => {
    const dispatch = useDispatch<AppDispatch>()
    const pedra = useSelector(getTipoPedrasSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditarOpen, setIsModalEditarOpen] = useState(false);
    const [editObj, setEditObj] = useState<any>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getTipoPedras())
    },[])

        const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <i className="ri-pencil-line"></i>
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <i style={{color: "red"}} className="ri-delete-bin-6-line"></i>
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];
    
      const handleCancel = () => {
        if(isModalEditarOpen) {
            window.location.reload()
        }
          form.resetFields();
        setIsModalOpen(false);
        setIsModalEditarOpen(false);
      };

      const onFinish = async (values: any) => {
        const data = {
            nome: values.nome,
            texto: "pedra",
        }
        if(isModalEditarOpen) {
            try {
                await editPedra(data, editObj[TipoPedraDictionary.ID])
                form.resetFields();
                setIsModalOpen(false)
                setIsModalEditarOpen(false)
                window.location.reload()
            } catch {
                toast.error("Ocorreu um erro ao criar pedra, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        } else {
            try {
                await postPedra(data)
                window.location.reload()
                form.resetFields();
                setIsModalOpen(false)
            } catch {
                toast.error("Ocorreu um erro ao criar pedra, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        }
      };

      useEffect(() => {
        if (isModalEditarOpen && editObj) {
          form.setFieldsValue({
            nome: editObj[TipoPedraDictionary.NOME]
          });
        } else {
          form.resetFields();
        }
      }, [isModalEditarOpen, editObj, form]);

    return (
        <S.Container>
            <Modal style={{overflow: "hidden"}} key={isModalEditarOpen ? editObj?.id : 'create'} title="Cadastrar Pedra" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <Form
                form={form}
                name="pedras"
                initialValues={isModalEditarOpen ? {nome: editObj?.[TipoPedraDictionary.NOME]} : undefined}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                label="Nome"
                name="nome"
                rules={[{ required: true, message: 'Por favor insira a pedra!' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
                </Form.Item>
            </Form>
            </Modal>
                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title-container'>
                        <div className='title'>
                            <i className="ri-bubble-chart-line ri-3x"></i>
                            <h1>Pedras</h1>
                        </div>
                        <Button onClick={() => {
                            setIsModalOpen(true)
                            setIsModalEditarOpen(false)
                        }} type='primary'>Cadastrar nova pedra</Button>
                    </div>

                    <div>
                 <Row className='title-table'>
                     <Col span={9}>
                         <h4>Nome</h4>
                     </Col>
                     <Col span={1}>
                         <h4>Ações</h4>
                     </Col>
                 </Row>
                 {pedra?.map((x) => (
                <Row key={x[TipoPedraDictionary.ID]} className='title-table'>
                    <Col span={9}>
                        <h5>{x[TipoPedraDictionary.NOME]}</h5>
                    </Col>
                    <Col className='actions' span={1}>
                    <Dropdown placement="bottomLeft" overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const edit = pedra.find(m => m[TipoPedraDictionary.ID] === x[TipoPedraDictionary.ID])

                                if(item?.danger) {
                                    try {
                                        await deletePedra(x[TipoPedraDictionary.ID].toString())
                                        dispatch(getTipoPedras())
                                    } catch {
                                        toast.error(`Ocorreu um erro ao deletar pedra ${x[TipoPedraDictionary.NOME]}`)
                                    }
                                    dispatch(getTipoPedras())
                                } else {
                                    setEditObj(edit)
                                    setIsModalEditarOpen(true);
                                    setIsModalOpen(true)
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <i style={{color: "black"}} className="ri-file-list-line ri-lg"></i>
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}