import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 100%;
    h6 {
        font-weight: 500;
        font-size: 12px;
    }
    h3 {
        color: #525252;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .button-whats {
        justify-content: center;
        background-color: #18AF50;
        height: 35px;
        color: white;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        font-family: Montserrat;
        font-weight: 500;
        padding: 18px 15px;
    }
    .button-whats:hover {
        color: white;
    }
    .ou {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        hr {
            border: 1px solid #D0D0D0;
            border-radius: 1px;
            width: 100%;
            height: 1px;
        }
        > h6 {
            text-decoration: none !important;
            cursor: default;
        }
    }
    .submit-button {
        height: 35px;
        width: 100%;
        background: radial-gradient(100% 5191.39% at 100% 0%, #024F68 32.29%, #047BA3 100%);
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 13px;
    }
`
