import { Button, Input } from 'antd';
import * as S from './styles';
import { Loja as LojaDictionary } from '../../shared/utils/dictionary';
import { formatPhoneNumber, isMobile } from '../../shared/utils/javascript';
import { useSelector } from 'react-redux';
import { getLoja } from '../../shared/redux/selectors';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { envioEmailNewsLetter, postWhats } from '../../service/api';
import LogoLitoral from '../../assets/images/litoral.png'
import axios from 'axios';

const Footer = () => {
    const loja = useSelector(getLoja)
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>("")
    const [ip, setIP] = useState()

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    return (
        <S.Container>
            <hr />
            <S.Content>
                {isMobile() ? null : (
                    <div>
                        <h2>Últimas novidades da Joalheria Carvalho</h2>
                        <h3>Seja o primeiro a saber dos novos e empolgantes designs, eventos especiais, inauguração de lojas e muito mais.</h3>
                        <Input value={email} onChange={(event) => {setEmail(event.target.value)}} className='input' type='email' placeholder="Digite seu e-mail aqui" bordered={false} />
                        <Button onClick={() => {
                            envioEmailNewsLetter({email: email})
                            setEmail("")
                        }} className='button'>INSCREVA-SE</Button>
                    </div>
                )}
                {isMobile() ? (
                    <div className='atendimento-institucional'>
                    <div>
                        <h2>Atendimento</h2>
                        <span onClick={() => {
                                if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`tel:+55${loja[LojaDictionary.TELEFONES]?.filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[0]?.[LojaDictionary.NOME_TELEFONE].replace(/[( )-]/g,"")}`, "_blank")
                        }}>
                            <i className="ri-phone-line ri-1x"></i>
                            <h4>{loja[LojaDictionary.TELEFONES]?.filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[0]?.[LojaDictionary.NOME_TELEFONE]}</h4>
                        </span>
                        <span onClick={() => {
                            if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações`, "_blank")
                        }}>
                        <i className="ri-whatsapp-line ri-1x"></i>
                            <h4>{formatPhoneNumber(loja[LojaDictionary.WHATS])}</h4>
                        </span>
                        <span onClick={() => {
                            window.open(`mailto:${loja[LojaDictionary.EMAILS][0][LojaDictionary.EMAIL]}`, "_blank")
                        }}>
                        <i className="ri-mail-line ri-1x"></i>
                            <h4>{loja[LojaDictionary.EMAILS]?.[0]?.[LojaDictionary.EMAIL]}</h4>
                        </span>
                    </div>
                    <div>
                        <h2>Institucional</h2>
                        <span onClick={() => {
                            navigate("/sobre-empresa")
                        }}>
                            <h4>Sobre a empresa</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/estoque")
                        }}>
                            <h4>Nossos produtos</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/contato")
                        }}>
                            <h4>Entre em contato</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/cotar")
                        }}>
                            <h4>Personalização</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/servicos")
                        }}>
                            <h4>Nossos serviços</h4>
                        </span>
                    </div>
                </div>
                ) : (
                    <>
                    <div>
                        <h2>Atendimento</h2>
                        <span onClick={() => {
                            window.open(`tel:+55${loja[LojaDictionary.TELEFONES].filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[0]?.[LojaDictionary.NOME_TELEFONE].replace(/[( )-]/g,"")}`, "_blank")
                        }}>
                            <i className="ri-phone-line ri-1x"></i>
                            <h4>{loja[LojaDictionary.TELEFONES]?.filter(x => x[LojaDictionary.TELEFONE_WHATS] === 0)?.[0]?.[LojaDictionary.NOME_TELEFONE]}</h4>
                        </span>
                        <span onClick={() => {
                            if(!ip) {
                                return
                                } else {
                                postWhats({
                                    ip: ip
                                })
                                }
                            window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações`, "_blank")
                        }}>
                        <i className="ri-whatsapp-line ri-1x"></i>
                            <h4>{formatPhoneNumber(loja[LojaDictionary.WHATS])}</h4>
                        </span>
                        <span onClick={() => {
                            window.open(`mailto:${loja[LojaDictionary.EMAILS][0][LojaDictionary.EMAIL]}`, "_blank")
                        }}>
                        <i className="ri-mail-line ri-1x"></i>
                            <h4>{loja[LojaDictionary.EMAILS]?.[0]?.[LojaDictionary.EMAIL]}</h4>
                        </span>
                    </div>
                    <div>
                        <h2>Institucional</h2>
                        <span onClick={() => {
                            navigate("/sobre-empresa")
                        }}>
                            <h4>Sobre a empresa</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/estoque")
                        }}>
                            <h4>Nossos produtos</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/contato")
                        }}>
                            <h4>Entre em contato</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/cotar")
                        }}>
                            <h4>Personalização</h4>
                        </span>
                        <span onClick={() => {
                            navigate("/servicos")
                        }}>
                            <h4>Nossos serviços</h4>
                        </span>
                </div>
                </>
                )}
                {isMobile() ? null : (
                    <div>
                        <h2>Fique conectado</h2>
                        <span>
                            <i onClick={() => {
                                window.open(loja[LojaDictionary.FB], "_blank")
                            }} className="ri-facebook-fill ri-xl"></i>
                            <i onClick={() => {
                                window.open(loja[LojaDictionary.INSTA], "_blank")
                            }} className="ri-instagram-line ri-xl"></i>
                            <i onClick={() => {
                                window.open(loja[LojaDictionary.YB], "_blank")
                            }} className="ri-youtube-fill ri-xl"></i>
                        </span>
                    </div>
                )}
                {isMobile() ? null : (
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14207.377819345613!2d-48.91572!3d-27.0982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df47994ad4e73b%3A0x95198dbcc561a156!2sCarvalho%20Joalheria%20e%20%C3%93ptica!5e0!3m2!1spt-BR!2sbr!4v1684424525470!5m2!1spt-BR!2sbr" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                )}
                {isMobile() ? (
                    <div>
                        <h2>Últimas novidades da Joalheria Carvalho</h2>
                        <h3>Seja o primeiro a saber dos novos e empolgantes designs, eventos especiais, inauguração de lojas e muito mais.</h3>
                        <Input value={email} onChange={(event) => {setEmail(event.target.value)}} className='input' type='email' placeholder="Digite seu e-mail aqui" bordered={false} />
                        <Button onClick={() => {
                            envioEmailNewsLetter({email: email})
                            setEmail("")
                        }} className='button'>INSCREVA-SE</Button>
                    </div>
                ) : null}
            </S.Content>
            {isMobile() ? (
                <div className='direitos'>
                    <h6>© 2023 Joalheria Carvalho | Todos os direitos reservados</h6>
                    <h6>Desenvolvido por <img onClick={() => {
                        window.open("https://www.litoralcar.com.br", "_blank")
                    }} src={LogoLitoral} alt="Litoral Car" /></h6>
                </div>
            ) : (
                <h6>© 2023 Joalheria Carvalho | Todos os direitos reservados | Desenvolvido por <img onClick={() => {
                    window.open("https://www.litoralcar.com.br", "_blank")
                }} src={LogoLitoral} alt="Litoral Car" /></h6>
            )}
        </S.Container>
    )
}

export default Footer;