import styled from 'styled-components';

export const Container = styled.div`

`

export const Content = styled.div`
    .breadcrumb {
        padding: 25px 100px;
    }
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 50px 80px;
        position: relative; 
        overflow: hidden;
    }
    img {
        max-height: auto;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 0 15px 15px 0;
    }
    .titulo {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }
    .form {
        background-color: white;
        border-radius: 15px 0 0 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        padding: 40px 70px;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
    .ant-input, .ant-select-selector {
        font-family: 'Montserrat';
        font-size: 13px;
    }
    .buttons {
        width: 100%;
        margin-top: 20px;
    }
    .titulo-form {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
    .amarelo {
        color: #CE8D4C;
    }
    .buttons {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
    .limpar {
        width: 100%;
        border: 1px solid #D6BF91;
        border-radius: 20px;
        background-color: white;
        outline: none;
        color: #7B7B7B;
        font-family: 'Montserrat';
    }
    .limpar:hover {
        background-color: transparent;
        color: #7B7B7B;
    }
    .enviar {
        width: 100%;
        font-family: 'Montserrat';
        border: 1px solid #D6BF91;
        border-radius: 20px;
        outline: none;
        background: linear-gradient(269.62deg, #CE8C4B 47.28%, #F8E68F 100%);
        font-weight: 500;
    }
    h1 {
        font-weight: 500;
        font-size: 20px;
    }
    h2 {
        font-weight: 500;
        font-size: 15px;
        max-width: 600px;
        text-align: center;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    hr {
        border: 1px solid #D6BF91;
        width: 100px;
    }

    @media (max-width: 991px) {
        padding: 20px;
        margin-bottom: 30px;
        .breadcrumb {
            padding: 0 0 25px 0;
        }
        .container {
            justify-content: center;
            display: flex;
            padding: 0;
        }
        .form {
            padding: 20px;
        }
    }
`