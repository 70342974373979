import * as S from './styles';
import { useEffect, useRef } from 'react';
import { Banner } from '../../shared/utils/dictionary';
import { isMobile } from '../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getBanner } from '../../shared/redux/selectors';
import { AppDispatch } from '../../App';
import { getBanners } from '../../shared/redux/slices/loja';

const BannerCarrossel = () => {
    const imagens = useSelector(getBanner)
    const dispatch = useDispatch<AppDispatch>()
    const containerRef = useRef<HTMLDivElement>(null);

    const handleScrollLeft = () => {
        const container = containerRef.current;
    
        if (container) {
          if (container.scrollLeft === 0) {
            container.scrollTo({
              left: container.scrollWidth,
              behavior: 'smooth',
            });
          } else {
            const scrollAmount = -window.innerWidth;
    
            container.scrollTo({
              left: container.scrollLeft + scrollAmount,
              behavior: 'smooth',
            });
          }
        }
      };

    const handleScrollRight = () => {
        const container = containerRef.current;
    
        if (container) {
          const scrollAmount = container.scrollLeft + window.innerWidth;
    
          if (scrollAmount >= container.scrollWidth) {
            container.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          } else {
            container.scrollTo({
              left: scrollAmount,
              behavior: 'smooth',
            });
          }
        }
      };

      useEffect(() => {
        const interval = setInterval(handleScrollRight, 8000);
    
        return () => clearInterval(interval);
      }, []);

    const bannerDesktopList = imagens.filter((x) => x[Banner.POSICAO] === "1").filter((x) => x[Banner.MOBILE] === "DESKTOP")
    const bannerMobileList = imagens.filter((x) => x[Banner.POSICAO] === "1").filter((x) => x[Banner.MOBILE] === "MOBILE")

    useEffect(() => {
        dispatch(getBanners())
    },[])

    return (
        <S.Container>
            <i id='left' onClick={handleScrollLeft} className="ri-arrow-left-s-line ri-2x"></i>
            <div className='scroll-line' ref={containerRef}>
                {isMobile() ? (
                    <div className='container-scroll'>
                        {bannerMobileList.map((image, i) => (
                            <img
                                onClick={() => {
                                    if(image.link.length <= 1) return
                                    window.open(image.link)
                                }}
                                key={i}
                                src={`${process.env.REACT_APP_API}${image?.[Banner.FOTO][Banner.ARQUIVO]}`}
                                alt={`Slide ${i + 1}`}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='container-scroll'>
                        {bannerDesktopList.map((image, i) => (
                            <img
                                onClick={() => {
                                  if(image.link.length <= 1) return
                                  window.open(image.link)
                                }}
                                key={i}
                                src={`${process.env.REACT_APP_API}${image?.[Banner.FOTO][Banner.ARQUIVO]}`}
                                alt={`Slide ${i + 1}`}
                            />
                        ))}
                    </div>
                )}
            </div>
           <i id='right' onClick={handleScrollRight} className="ri-arrow-right-s-line ri-2x"></i>
        </S.Container>
    )
}

export default BannerCarrossel;