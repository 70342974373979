import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    hr {
        background-color: #F6EFEF;
        border: 1px solid #F6EFEF;
        width: 80%;
    }
    h6 {
        font-weight: 500;
        margin-bottom: 8px;
        display: flex;
        align-items: self-end;
        gap: 5px;
        >img {
            cursor: pointer;
            height: 15px;
            filter: invert(75%) contrast(175%);
        }
    }
    .direitos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: self-start;
    width: 100%;

    .input {
        border-bottom: 1px solid black;
        border-radius: 0;
        margin-bottom: -10px;
    }
    .button {
        background: radial-gradient(100% 5191.39% at 100% 0%, #024F68 32.29%, #047BA3 100%);
        color: white;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
    }
    h2 {
        font-weight: 500;
        font-size: 15px;
        text-align: justify;
        font-variant: small-caps;
    }
    h3 {
        font-weight: 400;
        font-size: 12px;
        text-align: justify;
    }
    h4 {
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;
        line-break: anywhere;
    }
    >div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: calc(100vw / 5);
        >span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 10px;
        margin-top: -10px;
    }
    }
    i {
        cursor: pointer;
    }
    iframe {
        width: auto;
        height: 200px;
        border: none;
        border-radius: 15px;
    }
    @media (max-width: 991px) {
        flex-direction: column;
        padding: 0 20px;
        align-items: center;
        gap: 20px;

        .atendimento-institucional {
            flex-direction: row;
            width: 100%;
            justify-content: center;
            >div {
                width: 100%;
                >span {
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;
                    overflow: hidden;
                    max-width: calc(90vw / 2);
                }
            }
            
        }
        >div {
            max-width: 100%;
        }
    }
`
