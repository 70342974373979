import styled from "styled-components";

export const Container = styled.div`
    max-width: 100vw;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 50vh;
    background-color: white;

    img {
        object-fit: cover;
        width: 100vw;
        min-width: 100vw;
        height: auto;
        cursor: pointer;
    }
    .scroll-line {
        width: 100vw;
        max-width: 100vw;
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .container-scroll {
        width: 100vw;
        display: flex;
        flex-wrap: nowrap;
    }

    i {
        cursor: pointer;
        color: #D6BF91;
        z-index: 1;
    }
    #right {
        margin-right: 20px;
        right: 0;
        position: absolute;
    }
    #left {
        margin-right: -35px;
    }
    @media (max-width: 991px) {
        img {
            width: 100vw;
            max-width: 100vw;
        }
        #right {
        margin-left: -32px;
    }
    #left {
        margin-right: -32px;
    }
    }
`