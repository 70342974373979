import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { AsideAdmin } from '../AsideAdmin'
import { Button, Checkbox, Col, Collapse, Dropdown, Form, Input, Menu, Modal, Row, Select, Upload } from 'antd'
import { getBanner as getBannersSelector } from '../../../shared/redux/selectors'
import { Banner as BannerDictionary } from '../../../shared/utils/dictionary'
import { getBanners } from '../../../shared/redux/slices/loja'
import { useEffect, useState } from 'react'
import { deleteBanner, editBanner, postBanner } from '../../../service/apiAdmin'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Pos1 from '../../../assets/images/Pos1.png'
import Pos2 from '../../../assets/images/Pos2.png'
import Pos3 from '../../../assets/images/Pos3.png'
import Pos4 from '../../../assets/images/Pos4.png'
import Pos5 from '../../../assets/images/Pos5.png'
import Pos6 from '../../../assets/images/Pos6.png'
import Pos7 from '../../../assets/images/Pos7.png'

const { Panel } = Collapse;

export const Banner = () => {
    const dispatch = useDispatch<AppDispatch>()
    const banners = useSelector(getBannersSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [visibleImg, setVisibleImg] = useState(false);
    const [selectImg, setSelectImg] = useState();
    const [mobileCheck, setMobileCheck] = useState(false);
    const [isModalEditarOpen, setIsModalEditarOpen] = useState(false);
    const [editObj, setEditObj] = useState<any>(null);
    const [imagem, setImagem] = useState<any>();
    const [bannerFiltro, setBannerFiltro] = useState<any>();
    const usuarioCookies = Cookies.get('auth');
    const [conteudo, setConteudo] = useState('');
    const [form] = Form.useForm();
    const [valuePos, setValuePos] = useState(0);

    useEffect(() => {
        dispatch(getBanners())
    },[])
    useEffect(() => {
        setBannerFiltro(banners)
    },[banners])

        const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <i className="ri-pencil-line"></i>
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <i style={{color: "red"}} className="ri-delete-bin-6-line"></i>
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];
    
      const handleCancel = () => {
        if(isModalEditarOpen) {
            window.location.reload()
        }
          form.resetFields();
        setIsModalOpen(false);
        setIsModalEditarOpen(false);
      };

      const onFinish = async (values: any) => {
          if(isModalEditarOpen) {
            const data = {
                link: values.link,
                descricao: values.descricao,
                posicao: values.posicao ? Number(values.posicao) : 1,
                dispositivo: mobileCheck ? "MOBILE" : "DESKTOP",
                idImagem: editObj[BannerDictionary.FOTO],
                situacao: true
            }
            try {
                await editBanner(data, editObj[BannerDictionary.ID])
                form.resetFields();
                setIsModalOpen(false)
                setIsModalEditarOpen(false)
                window.location.reload()
            } catch {
                toast.error("Ocorreu um erro ao editar banner, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        } else {
                const formData = new FormData();
                formData.append('file', imagem);
                const response = await axios.post(`${process.env.REACT_APP_API}upload/1`, formData, {
                    headers: {
                        'token': usuarioCookies,
                        'Content-Type': 'multipart/form-data'
                    }
                    });

                    const dataCreate = {
                        idImagem: response.data.id,
                        link: values.link ? values.link : "/",
                        descricao: values.descricao ? values.descricao : "",
                        posicao: values.posicao ? Number(values.posicao) : 1,
                        dispositivo: mobileCheck ? "MOBILE" : "DESKTOP",
                        situacao: true
                    }
                    await postBanner(dataCreate)
                    form.resetFields();
                    setIsModalOpen(false)
                    window.location.reload()
        }
      };

      const handleUpload = async (file: any) => {
        setImagem(file)
        return false
      };

      const handleChangeSelect = (event: string) => {
        switch (event) {
            case "Todos":
                setBannerFiltro(banners)
              break;
            case "Mobile":
              setBannerFiltro(banners.filter(x => x.dispositivo === "MOBILE"))
              break;
            case "Desktop":
                setBannerFiltro(banners.filter(x => x.dispositivo === "DESKTOP"))
              break;
            default:
                setBannerFiltro(banners)
              break;
          }
      }

      const handleValuesChange = (_changedValues: any, allValues: { posicao: number }) => {
        const fieldValue = allValues.posicao;
        setValuePos(fieldValue);
      };

      useEffect(() => {
        if (isModalEditarOpen && editObj) {
            setImagem(editObj[BannerDictionary.FOTO])
            setMobileCheck(editObj[BannerDictionary.MOBILE] === "MOBILE" ? true : false)
          form.setFieldsValue({
            link: editObj[BannerDictionary.LINK],
            descricao: editObj[BannerDictionary.DESCRICAO],
            posicao: editObj[BannerDictionary.POSICAO],
            mobile: editObj[BannerDictionary.MOBILE],
          });
        } else {
          form.resetFields();
        }
      }, [isModalEditarOpen, editObj, form]);
      

    return (
        <S.Container>
            <Modal style={{overflow: "hidden"}} key={isModalEditarOpen ? editObj?.id : 'create'} title="Cadastrar Banner" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <Form
                onValuesChange={handleValuesChange}
                form={form}
                name="banner"
                initialValues={isModalEditarOpen ? {link: editObj?.[BannerDictionary.ID]} : undefined}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                label="Link"
                name="link"
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Posição"
                name="posicao"
                rules={[{ required: true, message: 'Por favor escolha a posição!' }]}
                >
                <Select
                    defaultValue="Posição"
                    options={[
                        {value: 1, label: 1},
                        {value: 2, label: 2},
                        {value: 3, label: 3},
                        {value: 4, label: 4},
                        {value: 5, label: 5},
                        {value: 6, label: 6},
                        {value: 7, label: 7},
                    ]}
                    />
                </Form.Item>

                <Form.Item
                label="Texto (Máx) 650"
                name="descricao"
                >
                <ReactQuill readOnly={valuePos === 1 || valuePos === 2} defaultValue={isModalEditarOpen ? editObj[BannerDictionary.DESCRICAO] : ""} style={{ minWidth: 370, maxWidth: 450, paddingBottom: 10}} value={conteudo} onChange={(value) => {setConteudo(value)}} />
                </Form.Item>

                <Form.Item
                label="Mobile"
                name="mobile"
                >
                    <Checkbox defaultChecked={editObj?.[BannerDictionary.MOBILE] === "MOBILE" ? true : false} value={mobileCheck} onChange={(event) => {
                        setMobileCheck(event.target.checked)
                    }} />
                </Form.Item>

                {isModalEditarOpen ? <img style={{width: "200px"}} src={`${process.env.REACT_APP_API}${editObj[BannerDictionary.FOTO][BannerDictionary.ARQUIVO]}`} alt="Imagem banner" /> : (
                    <Form.Item
                    label="Imagem"
                    name="imagem"
                    >
                    <Upload
                    action="/"
                    listType="picture"
                    beforeUpload={handleUpload}
                    maxCount={1}
                    >
                    <Button icon={<i className="ri-folder-upload-line"></i>}>Upload</Button>
                    </Upload>
                    </Form.Item>
                )}

                <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
                </Form.Item>
            </Form>
            </Modal>
                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title-container'>
                        <div className='title'>
                            <i className="ri-image-edit-line ri-3x"></i>
                            <h1>Banners</h1>
                        </div>
                        <div>
                            <Select
                                onChange={(event) => handleChangeSelect(event)}
                                defaultValue="Todos"
                                bordered={false}
                                dropdownStyle={{minWidth: 'fit-content'}}
                                options={[
                                    {value: "Todos", label: "Todos"},
                                    {value: "Mobile", label: "Mobile"},
                                    {value: "Desktop", label: "Desktop"},
                                ]}
                            />
                            <Button onClick={() => {
                                setIsModalOpen(true)
                                setIsModalEditarOpen(false)
                            }} type='primary'>Cadastrar novo banner</Button>
                        </div>
                    </div>

                    <div>
                        <div className="banner-table-container" style={{ overflowX: "auto" }}>
                            <Row className='title-table'>
                                <Col span={3}>
                                    <h4>Foto</h4>
                                </Col>
                                <Col span={2}>
                                    <h4>Link</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Posição</h4>
                                </Col>
                                <Col span={2}>
                                    <h4>Dispositivo</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Ações</h4>
                                </Col>
                            </Row>
                            {bannerFiltro?.map((x: any) => (
                            <Row key={x[BannerDictionary.ID]} className='title-table'>
                                <Col span={3}>
                                <img style={{maxWidth: 150}} src={`${process.env.REACT_APP_API}${x[BannerDictionary.FOTO][BannerDictionary.ARQUIVO]}`} alt={x[BannerDictionary.LINK]} />
                                </Col>
                                <Col span={2}>
                                    <h5>{x[BannerDictionary.LINK]}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[BannerDictionary.POSICAO]}</h5>
                                </Col>
                                <Col span={2}>
                                    <h5>{x[BannerDictionary.MOBILE]}</h5>
                                </Col>
                                <Col className='actions' span={1}>
                                <Dropdown placement="bottomLeft" overlay={
                                    <Menu>
                                        {menuItems.map((item: any) => (
                                        <Menu.Item onClick={async () => {
                                            const edit = banners.find(m => m[BannerDictionary.ID] === x[BannerDictionary.ID])

                                            if(item?.danger) {
                                                try {
                                                    await deleteBanner(x[BannerDictionary.ID].toString())
                                                    dispatch(getBanners())
                                                } catch {
                                                    toast.error(`Ocorreu um erro ao deletar banner ${x[BannerDictionary.LINK]}`)
                                                }
                                                dispatch(getBanners())
                                            } else {
                                                setEditObj(edit)
                                                setIsModalEditarOpen(true);
                                                setIsModalOpen(true)
                                            }
                                        }} key={item?.key} danger={item?.danger}>
                                            {item.icon} {item.label}
                                        </Menu.Item>
                                        ))}
                                    </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <i style={{color: "black"}} className="ri-file-list-line ri-lg"></i>
                                    </a>
                                    </Dropdown>
                                </Col>
                            </Row>
                            ))} 
                        </div>
                <Modal
                className="custom-modal"
                width={"100vw"}
                open={visibleImg}
                onCancel={() => {setVisibleImg(false)}}
                footer={null}
                style={{top: 0, margin: 0}}
            >
                <div style={{display: "flex", flexDirection: "row", width: "90vw", margin: "auto", height: "calc(80vw / 2", justifyContent: "center", alignItems: "center"}}>
                    <img style={{width: "calc(90vw / 1)", height: "calc(80vw / 2", objectFit: "cover"}} src={selectImg} alt="Imagem" />
                </div>
            </Modal>
                <Collapse className='collapse'>
                    <Panel header="Guia de posições" key="1">
                        <div className='guia'>
                            <h4>Posição 1:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos1)
                            }} src={Pos1} alt="Posição 1" />
                            <h5>Banner principal.</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 2:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos2)
                            }} src={Pos2} alt="Posição 2" />
                            <h5>Banner que fica entre os produtos em destaque e as categorias.</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 3:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos3)
                            }} src={Pos3} alt="Posição 3" />
                            <h5>Banner das “Joias feitas a mão”, esse banner possui adição de texto.</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 4:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos4)
                            }} src={Pos4} alt="Posição 4" />
                            <h5>Banner do “Conserto de joia”, esse banner possui adição de texto.</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 5:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos5)
                            }} src={Pos5} alt="Posição 5" />
                            <h5>Banner do “Um pedaço da nossa história”, esse banner possui adição de texto.</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 6:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos6)
                            }} src={Pos6} alt="Posição 6" />
                            <h5>{`Página sobre a empresa > Sobre nós`}</h5>
                        </div>
                        <div className='guia'>
                            <h4>Posição 7:</h4>
                            <img onClick={() => {
                                setVisibleImg(true);
                                setSelectImg(Pos7)
                            }} src={Pos7} alt="Posição 7" />
                            <h5>{`Esse banner se localiza na página “Sobre a empresa”, e faz parte do “Sobre a empresa”, esse banner possui adição de texto.`}</h5>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </S.Container>
    )
}