import styled from 'styled-components';

export const Container = styled.div`

`

export const Content = styled.div`
    padding: 25px 100px;

    .middle-content {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 13%;
    }
    h2 {
        font-weight: 500;
        font-size: 20px;
    }
    h3 {
        margin-top: 10px;
        font-weight: 500;
        font-size: 15px;
        max-width: 650px;
        text-align: center;
    }
    h4 {
        font-weight: 500;
        font-size: 13px;
    }
    h5 {
        font-weight: 500;
        color: #024F68;
    }
    hr {
        margin-top: 15px;
        width: 100px;
        border: 1px solid #D6BF91;
    }
    .info-mapa {
        margin-top: 35px;
        background-color: white;
        box-shadow: 0px 4px 10px rgba(216, 216, 216, 0.25);
        border-radius: 5px;
        display: flex;
        width: 100%;
        flex-direction: row;
    }
    iframe {
        width: 400px;
        height: 300px;
        border: none;
        border-radius: 15px;
    }
    .infos {
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 20px;
    }
    .icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }
    i {
        cursor: pointer;
    }
    .grid-card {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }
    .card {
        cursor: pointer;
        padding: 10px 15px;
        display: flex;
        width: calc(100% / 4);
        flex-direction: column;
        align-items: center;
        gap: 10px;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.1);
        border-radius: 5px;
    }
    .form {
        margin-top: 30px;
        padding: 30px 50px;
        gap: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .submit-button {
        width: 100%;
        background: radial-gradient(100% 5191.39% at 100% 0%, #024F68 32.29%, #047BA3 100%);
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 13px;
    }

    @media (max-width: 991px) {
        padding: 10px;
        .middle-content  {
            padding: 0px;
        }
        .card {
            width: 100%;
        }
        .grid-card {
            box-sizing: border-box;
            overflow: auto;
            padding-bottom: 10px;
        }
        h4 {
            white-space: nowrap;
        }
        .form {
            margin-bottom: 30px;
            padding: 30px 20px;
        }
    }
`