import * as S from './styles'
import CardProduto from '../../../components/CardProduto'
import { useState } from 'react'
import { isMobile } from '../../../shared/utils/javascript'
import { useSelector } from 'react-redux'
import { getProdutos } from '../../../shared/redux/selectors'
import { Produto as ProdutoDictionary } from '../../../shared/utils/dictionary'

const Destaques = () => {
    const produtos = useSelector(getProdutos).filter(x => x[ProdutoDictionary.SITUACAO] === 1)

    const [index, setIndex] = useState([0,1,2,3])

    const incrementNumbers = () => {
        const lastNumber = index[index.length - 1];
        if (lastNumber === produtos.length - 1) {
            setIndex([0, 1, 2, 3]);
        } else {
            const updatedNumbers = index.map((number) => number + 1);
            setIndex(updatedNumbers);
        }
      };
      
    const decrementNumbers = () => {
        const firtNumber = index[0];
        if (firtNumber === 0) {
            setIndex([produtos.length - 4, produtos.length - 3, produtos.length - 2, produtos.length - 1]);
        } else {
            const updatedNumbers = index.map((number) => number - 1);
            setIndex(updatedNumbers);
        }
      };

    return (
        <S.Container>
            <div className='title'>
                <h3>PEÇA EM DESTAQUE</h3>
                <h4>Peças escolhidas a dedo, que combinam com tudo. </h4>
                <hr />
            </div>
            {isMobile() ? (
                <div className='mobile-grid'>
                    {produtos.slice(0, 6).map((x) => (
                        <CardProduto key={x[ProdutoDictionary.ID]} produto={x} />
                    ))}
                </div>
            ) : (
                <S.Carrossel>
                <i onClick={() => {
                    decrementNumbers()
                }} style={{color: "#D6BF91", cursor:"pointer"}} className="ri-arrow-left-s-line ri-2x" />
                    <CardProduto produto={produtos[index[0]]} />
                    <CardProduto produto={produtos[index[1]]} />
                    <CardProduto produto={produtos[index[2]]} />
                    <CardProduto produto={produtos[index[3]]} />
                <i onClick={() => {
                    incrementNumbers()
                }} style={{color: "#D6BF91", cursor:"pointer"}} className="ri-arrow-right-s-line ri-2x" />
                </S.Carrossel>
            )}
        </S.Container>
    )
}

export default Destaques;