import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: baseline;

    h3{
        font-weight: 500;
        font-size: 15px;
    }
    .ant-select-selection-item {
        font-family: "Montserrat";
        font-weight: 500;
    }
    .div-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        >button {
            width: 100%;
            border: 1.2px solid #024F68;
            border-radius: 0px;
            outline: none;
            font-family: 'Montserrat';
            font-weight: 500;
        }
    }
`
export const OpenFilter = styled.div`
    @keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
    }
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 998;
    height: 100vh;
    top: 0;
    left: 0;

    .content {
        animation: slideInFromLeft 0.5s forwards;
        background-color: white;
        width: 80%;
        position: fixed;
        z-index: 999;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 10px ;
    }
    .medium-checkbox .ant-checkbox-inner {
        width: 16px !important;
        height: 16px;
    }
    .input-search {
        width: 100%;
        font-family: Montserrat;
        ::placeholder {
            font-family: Montserrat;
        }
    }
    .ant-input, .ant-btn  {
        border: none;
        background-color: #FAFAFA;
        border-radius: 3px;
    }
    #close {
        display: flex;
        justify-content: end;
        margin-bottom: 20px;
    }
    .ant-collapse{
        border: none;
        background-color: white;
        margin-top: 20px;
    }
    .ant-collapse-content {
        border-top: none;
        margin-left: 20px;
    }
    .ant-collapse-content-box {
        padding: 0 10px 10px 10px !important;
        display: flex;
        flex-direction: column;
    }
    .ant-checkbox-wrapper {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
    }
    .ant-checkbox-inner {
        width: 10px;
        height: 10px;
    }
    .button-grid {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 15px;
        margin-top: 30px;
    }
    .limpar {
        width: 100%;
        border-radius: 0px;
        border: 1px solid #024F68;
        font-family: Montserrat;
        font-weight: 500;
    }
    .buscar {
        width: 100%;
        border-radius: 0px;
        border: 1px solid #024F68;
        background-color: #024F68;
        color: white;
        font-family: Montserrat;
        font-weight: 500;
    }
    .ant-collapse-header, .ant-collapse-item {
        border-radius: 0 !important;
    }
    a {
        width: 100%;
    }
`