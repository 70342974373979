import BannerCarrossel from '../../components/BannerCarrossel';
import Header from '../../components/Header';
import Destaques from './Destaques';
import * as S from './styles'
import { Banner } from '../../shared/utils/dictionary';
import Categorias from './Categorias';
import { isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../App';
import { getBanners, getProdutos } from '../../shared/redux/slices/loja';
import RedesSociais from '../../components/RedesSociais';
import { getBanner } from '../../shared/redux/selectors';

const Home: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>()
    function createMarkup(html: any) {
        return { __html: html };
      }

      const imagens = useSelector(getBanner)

    useEffect(() => {
        dispatch(getProdutos())
        dispatch(getBanners())
    },[])

    useEffect(() => {
        scrollToTop();
      }, []);

    const bannerPos2Dektop = imagens.filter((x) => x[Banner.POSICAO] === "2").filter((y) => y[Banner.MOBILE] === "DESKTOP")
    const bannerPos3Dektop = imagens.filter((x) => x[Banner.POSICAO] === "3").filter((y) => y[Banner.MOBILE] === "DESKTOP")
    const bannerPos4Dektop = imagens.filter((x) => x[Banner.POSICAO] === "4").filter((y) => y[Banner.MOBILE] === "DESKTOP")
    const bannerPos5Dektop = imagens.filter((x) => x[Banner.POSICAO] === "5").filter((y) => y[Banner.MOBILE] === "DESKTOP")

    const bannerPos2Mobile = imagens.filter((x) => x[Banner.POSICAO] === "2").filter((y) => y[Banner.MOBILE] === "MOBILE")
    const bannerPos3Mobile = imagens.filter((x) => x[Banner.POSICAO] === "3").filter((y) => y[Banner.MOBILE] === "MOBILE")
    const bannerPos4Mobile = imagens.filter((x) => x[Banner.POSICAO] === "4").filter((y) => y[Banner.MOBILE] === "MOBILE")
    const bannerPos5Mobile = imagens.filter((x) => x[Banner.POSICAO] === "5").filter((y) => y[Banner.MOBILE] === "MOBILE")

    return (
        <S.Container>
            <Header />
            {isMobile() ? null : (<RedesSociais />)}
            <BannerCarrossel />
            <Destaques />
            <img onClick={()=>{window.open(isMobile() ? bannerPos2Mobile?.[0]?.[Banner.LINK] : bannerPos2Dektop?.[0]?.[Banner.LINK])}} className='banner2' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos2Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos2Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
            <Categorias />
            <div className='background'>
                <div className='banner'>
                    {isMobile() ? (
                    <>
                        <div>
                            <h2>Jóias feitas a mão</h2>
                            <p dangerouslySetInnerHTML={createMarkup(bannerPos3Mobile?.[0]?.[Banner.DESCRICAO])}></p>
                            <h5 style={{textAlign: "end"}} className='button' onClick={()=>{window.open(isMobile() ? bannerPos3Mobile?.[0]?.[Banner.LINK] : bannerPos3Dektop?.[0]?.[Banner.LINK])}}>COTAR UMA JOIA PERSONALIZADA <i className="ri-arrow-right-s-line ri-1x"></i></h5>
                        </div>
                        
                        <img className='banner3' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos3Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos3Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
                    </>) : (
                    <>
                        <img className='banner3' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos3Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos3Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
                        <div>
                            <h2>Jóias feitas a mão</h2>
                            <p dangerouslySetInnerHTML={createMarkup(bannerPos3Dektop?.[0]?.[Banner.DESCRICAO])}></p>
                            <h5 className='button' onClick={()=>{window.open(isMobile() ? bannerPos3Mobile[0][Banner.LINK] : bannerPos3Dektop[0][Banner.LINK])}}>COTAR UMA JOIA PERSONALIZADA <i className="ri-arrow-right-s-line ri-1x"></i></h5>
                        </div>
                    </>)}
                    
                </div>
                <div className='banner'>
                    <div>
                        <h2 style={{textAlign: "end"}}>Conserto de jóia</h2>
                        <p style={{ maxWidth: "100%"}} dangerouslySetInnerHTML={createMarkup(isMobile() ? bannerPos4Mobile?.[0]?.[Banner.DESCRICAO] : bannerPos4Dektop?.[0]?.[Banner.DESCRICAO])}></p>
                        <h5 style={!isMobile() ? {textAlign: "end"} : {}} className='button' onClick={()=>{window.open(isMobile() ? bannerPos4Mobile?.[0]?.[Banner.LINK] : bannerPos4Dektop?.[0]?.[Banner.LINK])}}>PRECISO DE UMA AJUDA EM UMA JOIA <i className="ri-arrow-right-s-line ri-1x"></i></h5>
                    </div>
                    
                    <img  className='banner4' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos4Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos4Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
                </div>
            </div>
            <div className='banner'>
            {isMobile() ? (
                    <>
                        <div>
                            <h2>Um pedaço da nossa história</h2>
                            <p dangerouslySetInnerHTML={createMarkup(bannerPos5Mobile?.[0]?.[Banner.DESCRICAO])}></p>
                            <h5 className='button' onClick={()=>{window.open(isMobile() ? bannerPos5Mobile?.[0]?.[Banner.LINK] : bannerPos5Dektop?.[0]?.[Banner.LINK])}}>SAIBA MAIS <i className="ri-arrow-right-s-line ri-1x"></i></h5>
                        </div>
                        
                        <img className='banner5' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos5Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos5Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
                    </>) : (
                    <>
                        <img className='banner5' src={isMobile() ? `${process.env.REACT_APP_API}${bannerPos5Mobile?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${bannerPos5Dektop?.[0]?.[Banner.FOTO][Banner.ARQUIVO]}`} alt="" />
                        <div>
                            <h2>Um pedaço da nossa história</h2>
                            <p dangerouslySetInnerHTML={createMarkup(bannerPos5Dektop?.[0]?.[Banner.DESCRICAO])}></p>
                            <h5 className='button' onClick={()=>{window.open(isMobile() ? bannerPos5Mobile?.[0]?.[Banner.LINK] : bannerPos5Dektop?.[0]?.[Banner.LINK])}}>SAIBA MAIS <i className="ri-arrow-right-s-line ri-1x"></i></h5>
                        </div>
                    </>)}            
                </div>
        </S.Container>
    )
}

export default Home;