import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100vw / 6);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: left;
    z-index: 9999;
    position: relative;


    span {
        position: relative;
        display: inline-block;
    }

    img {
        transition: 100ms linear;
        max-width: 100%;
        max-height: auto;
        cursor: pointer;
        border-radius: 10px;
    }
    h3 {
        margin-top: 10px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 15px !important;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 991px) {
        width: 100%;

        h3 {
            font-size: 18px !important;
            margin-top: 12px !important;
        }
    }
`