import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 99999;
    .fb {
        cursor: pointer;
        border-radius: 100%;
        background-color: #2A5FE7;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: #2A5FE7;
            background-color: white;
            border-radius: 100%;
            padding: 5px 2px;
        }
    }
    .whats {
        cursor: pointer;
        border-radius: 100%;
        background-color: #07DA4D;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: white;
            border-radius: 100%;
            padding: 5px 2px;
        }
    }
    .insta {
        cursor: pointer;
        border-radius: 100%;
        background: linear-gradient(225.26deg, #DB00FF 8.93%, #FF2C2C 51.35%, #D6DA07 93.14%);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: white;
            border-radius: 100%;
            padding: 5px 2px;
        }
    }
`

