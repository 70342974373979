import { AsideAdmin } from '../AsideAdmin'
import { HeaderAdmin } from '../HeaderAdmin'
import 'react-quill/dist/quill.snow.css';
import * as S from './styles'
import { SetStateAction, useEffect, useState } from 'react'
import { Button, Form, Input, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { getLoja } from '../../../shared/redux/slices/loja'
import { getLoja as getLojaSelector } from '../../../shared/redux/selectors'
import { toast } from 'react-toastify'
import { Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import { deleteEmail, deleteTelefone, postConfig, postEmail, postTelefone } from '../../../service/apiAdmin';

interface TagItem {
    id: number;
    value: string;
  }

export const Configuracoes = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>()
    const loja = useSelector(getLojaSelector)
    const [tags, setTags] = useState<TagItem[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [tagsEmail, setTagsEmail] = useState<TagItem[]>([]);
    const [inputValueEmail, setInputValueEmail] = useState('');
  
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          const inputValue = event.currentTarget.value.trim();
          if (inputValue) {
            const newTag: TagItem = {
              id: Date.now(),
              value: inputValue
            };
            setTags([...tags, newTag]);
            setInputValue("");
          }
        }
      };
  
    const handleInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
      setInputValue(event.target.value);
    };

    const handleKeyDownEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          const inputValueEmail = event.currentTarget.value.trim();
          if (inputValueEmail) {
            const newTag: TagItem = {
              id: Date.now(),
              value: inputValueEmail
            };
            setTagsEmail([...tagsEmail, newTag]);
            setInputValueEmail("");
          }
        }
      };

      const handleRemoveTagTelefone = (tag: TagItem) =>  {
        if(!loja.telefones.find(x => x.nome === tag.value.toString())) return
          deleteTelefone(tag.id.toString())
      }

      const handleRemoveTagEmail = (tag: TagItem) => {
        if(!loja.emails.find(x => x.email === tag.value.toString())) return 
        deleteEmail(tag.id.toString())
      }
  
    const handleInputChangeEmail = (event: { target: { value: SetStateAction<string>; }; }) => {
      setInputValueEmail(event.target.value);
    };

    const handleKeyPress = (event: { key: string; preventDefault: () => void; }) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };

    const onFinish = (data: any) => {
        const telefones = tags?.map(obj => ({
            nome: obj.value,
            telefone: Number(obj.value.replace(/\D/g, "")),
            tipo: "fixo",
            whatsapp: false
          }));
        const emails = tagsEmail?.map(obj => ({
            nome: "Email",
            email: obj.value,
            tipo: "Email"
          }));
          
        const dataEnvio = {
            nome: "Joalheria Carvalho",
            horarioAtendimento: data.horario,
            instagram: data.insta,
            facebook: data.fb,
            whatsapp: Number(data.whats),
            youtube: data.yb,
            sobre: "conteudo",
            //Endereço mudar aqui!!
            cidade: "Brusque",
            bairro: "São Luiz",
            rua: "Rua João Olinger",
            cep: 88351165,
            complemento: "Complemento"
        }

        try {
            if(telefones.length > 0) {
                const listaDiferenteTelefones = telefones.filter(item2 => {
                    return !loja.telefones.some(item1 => item1.nome === item2.nome);
                  });
                  listaDiferenteTelefones.forEach(element => {
                      postTelefone(element)
                  });
            }
            if(emails.length > 0) {
                const listaDiferenteEmails = emails.filter(item2 => {
                    return !loja.emails.some(item1 => item1.email === item2.email);
                  });
                  listaDiferenteEmails.forEach(element => {
                    postEmail(element)
                  });
            }
            postConfig(dataEnvio)
            toast.success("Informações atualizadas com sucesso")
        } catch (error) {
            toast.error("Ocorreu um erro ao atualizar as informações")
        }

    }

    useEffect(()=>{
        dispatch(getLoja())
    },[])

    useEffect(() => {
        if (loja) {
          form.setFieldsValue({
            insta: loja[LojaDictionary.INSTA],
            fb: loja[LojaDictionary.FB],
            horario: loja[LojaDictionary.HORARIOS],
            yb: loja[LojaDictionary.YB],
            whats: loja[LojaDictionary.WHATS],
          });
          const telefones = loja.telefones.map((x) => ({
            id: Number(x.idTelefone),
            value: x.nome
          }));
          setTags(telefones)
          const emails = loja.emails.map((x) => ({
            id: Number(x.idEmail),
            value: x.email
          }));
          setTagsEmail(emails)
        } else {
          form.resetFields();
        }
      }, [loja]);

    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <i className="ri-tools-line ri-lg"></i>
                <h1>Configurações</h1>
            </div>
            <h2>Editar configurações</h2>
                <Form
                    style={{width: "100%", display: "flex", flexDirection: "column", gap: "30px"}}
                    form={form}
                    name='config'
                    layout='vertical'
                    onFinish={onFinish}
                    onKeyPress={handleKeyPress}
                    autoComplete="off"
                    >
            <div className='content'>
                    <div className='base-info'>
                        <h3>Instagram <i className="ri-instagram-line"></i></h3>
                        <Form.Item name="insta" rules={[{ required: true, message: 'Por favor insira o link do instagram!' }]}>
                            <Input />
                        </Form.Item>
                        <h3>Facebook <i className="ri-facebook-line"></i></h3>
                        <Form.Item name="fb">
                            <Input />
                        </Form.Item>
                        <h3>Youtube <i className="ri-youtube-line"></i></h3>
                        <Form.Item name="yb">
                            <Input />
                        </Form.Item>
                        <h3>Whatsapp <i className="ri-whatsapp-line"></i></h3>
                        <Form.Item name="whats">
                            <Input />
                        </Form.Item>
                        <h3>Horários</h3>
                            <Form.Item name="horario">
                                <Input />
                            </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{backgroundColor: "green"}} type="primary">Salvar</Button>
                        </Form.Item>
                    </div>
                    <div>
                    <div className='telefone'>
                        <div>
                            {tags.map((tag, index) => (
                                <Tag closable onClose={() => handleRemoveTagTelefone(tag)} key={index}>{tag.value}</Tag>
                            ))}
                        </div>
                        <Input
                            style={{width: "50%", marginTop: "15px"}}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Digite e pressione Enter para adicionar um numero de telefone, não esqueça de clicar em salvar!"
                        />
                    </div>
                    <div className='email'>
                        <div>
                            {tagsEmail.map((tag, index) => (
                                <Tag closable onClose={() => handleRemoveTagEmail(tag)} key={index}>{tag.value}</Tag>
                            ))}
                        </div>
                        <Input
                            style={{width: "50%", marginTop: "15px"}}
                            value={inputValueEmail}
                            onChange={handleInputChangeEmail}
                            onKeyDown={handleKeyDownEmail}
                            placeholder="Digite e pressione Enter para adicionar um e-mail, não esqueça de clicar em salvar!"
                        />
                    </div>
                    </div>
                </div>
                    
                    </Form>
        </S.Container>
    )
}