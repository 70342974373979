import styled from 'styled-components';

export const Container = styled.div`
    .breadcrumb {
        padding: 20px 100px;
    }
    .ant-breadcrumb-link {
        font-family: "Montserrat";
        font-size: 13px;
    }

    @media (max-width: 991px) {
        .breadcrumb {
            padding: 10px;
        }
    }
`
export const Content = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 40px;
    padding: 25px 100px;

    h1 {
        font-weight: 500;
        font-size: 18px;
    }
    h2 {
        font-weight: 400;
        font-size: 15px;
        margin-top: 15px;
    }
    h3 {
        font-weight: 500;
        font-size: 13px;
    }
    h4 {
        font-weight: 500;
            font-size: 15px;
    }
    h6 {
        font-weight: 500;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
    }

    @media (max-width: 991px) {
        padding: 0 20px 40px 20px;
        display: block;
    }
`
export const Info = styled.div`
    background-color: white;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.25);
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    
    .especs {
        display: flex;
        flex-direction: row;
        gap: 100px;
        margin-top: 30px;
        border-bottom: 1px solid #D0D0D0;
        padding-bottom: 20px;
        width: 100%;

        h5 {
            color: #D6BF91;
            font-weight: 500;
            font-size: 10px;
        }
    }
    .tamanho {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }
    .compartilhar {
        display: flex;
        flex-direction: row;
        gap: 5px;
        margin-top: 15px;
    }

    @media (max-width: 991px) {
        max-width: 90vw;
        .especs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }
    }
`
export const Interesse = styled.div``

export const Carrossel = styled.div`

    .slick-slide {
        transform: translate(40%, 0); 
    }

    .square {
        width: calc(90vw / 3) !important;
        height: calc(90vw / 4);
        padding: 0 10px;
        >img {
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    @media (max-width: 991px) {
        .slick-slide {
        transform: translate(100%, 0); 
    }
        .square {
            width: calc(90vw);
            height: calc(90vw / 2);
            min-width: calc(90vw);
            padding: 8px;
            >img {
                border-radius: 10px;
                object-fit: cover;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }
`

export const Contato = styled.div`
    height: fit-content;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.25);
    border-radius: 5px;

    @media (max-width: 991px) {
        margin-top: 20px;
    }
`