import styled from "styled-components";

export const Container = styled.div`
  background-color: #212529;
  border-bottom: 1px solid white;
  padding: 15px;
  padding-right: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  i {
    color: white;
  }
  a {
    color: white;
    text-decoration: none;
    font-family: Montserrat;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`