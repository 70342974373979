import { RootState } from "../../../shared/types";

export const getProdutos = (state: RootState) =>
  state.lojaState.produtos;

export const getProdutosFiltro = (state: RootState) =>
  state.lojaState.produtosFiltro;

export const getProduto = (state: RootState) =>
  state.lojaState.produto;

export const getCategorias = (state: RootState) =>
  state.lojaState.categorias;

export const getMateriais = (state: RootState) =>
  state.lojaState.materiais;

export const getTipoPedras = (state: RootState) =>
  state.lojaState.tipoPedras;

export const getTamanhos = (state: RootState) =>
  state.lojaState.tamanhos;

export const getCampanhas = (state: RootState) =>
  state.lojaState.campanhas;

export const getSelects = (state: RootState) =>
  state.lojaState.filtro.populate;

export const getSelect = (state: RootState) =>
  state.lojaState.filtro.select;

export const getLoja = (state: RootState) =>
  state.lojaState.loja;

export const getBanner = (state: RootState) =>
  state.lojaState.banners;

export const getEmailsRecebidos = (state: RootState) =>
  state.lojaState.emails;

export const getVisitas = (state: RootState) =>
  state.lojaState.visitas;

export const getWhats = (state: RootState) =>
  state.lojaState.whats;
