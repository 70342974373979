import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-width: 100%;
    .title-interesse {
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 3px;
    }
    @media (max-width: 991px) {
        margin-top: 10px;
        .title-interesse {
        font-weight: 500;
        font-size: 18px;
    }
    }
    `
export const Carrossel = styled.div`
    max-width: 100%;
    width: 100%;
    max-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: 991px) {
        justify-content: start;
        margin-bottom: 20px;
        overflow: auto;
        padding-bottom: 10px;
    }
   
`
