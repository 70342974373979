  export const isMobile = () => {
    const mobileWidth = 991;
    return window.innerWidth < mobileWidth;
  };

  export const removerAcentosEMinusculo = (str: string) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  export function formatPhoneNumber(input: string) {
    const cleaned = input.toString().replace(/\D/g, '');
  
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`;
    }

    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
  }

  export const scrollToTop = () => {
    const currentPosition = window.scrollY;
    const startPosition = currentPosition;
    const endPosition = 0;
    const animationDuration = 500;
    let startTime: number | null = null;

    function scrollAnimation(currentTime: number) {
      if (!startTime) startTime = currentTime;
      const progress = currentTime - startTime;
      const nextPosition = easeInOutCubic(progress, startPosition, endPosition - startPosition, animationDuration);
      window.scrollTo(0, nextPosition);

      if (progress < animationDuration) {
        requestAnimationFrame(scrollAnimation);
      }
    }

    function easeInOutCubic(t: number, b: number, c: number, d: number) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t * t + b;
      t -= 2;
      return (c / 2) * (t * t * t + 2) + b;
    }

    requestAnimationFrame(scrollAnimation);
  };

  export const removeAccents = (input: string) => {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  export const formatarDinheiro = (event: any) => {
    // Remove qualquer caractere que não seja número
    let valor = event.target.value.replace(/\D/g, '');
  
    // Converte para número e divide por 100 para considerar os centavos
    valor = (Number(valor) / 100).toFixed(2);
  
    // Converte o valor para string e aplica o separador de milhares com ponto
    valor = valor.replace('.', ',');
  
    // Adiciona o ponto como separador de milhar
    const valorFormatado = valor.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    return valorFormatado;
  };
  

  
  
  
  