import * as S from './styles'
import CardProduto from '../../../components/CardProduto';
import { useEffect, useState } from 'react';
import { isMobile } from '../../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getProdutos as getProdutosSelector } from '../../../shared/redux/selectors';
import { AppDispatch } from '../../../App';
import { getProdutos } from '../../../shared/redux/slices/loja';

const Interesse = () => {

    const produtos = useSelector(getProdutosSelector)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getProdutos())
    },[])
        
    function generateRandomNumbers() {
      const numbers = [];
      
      for (let i = 0; i < 3; i++) {
        const randomNumber = Math.floor(Math.random() * produtos.length);
        numbers.push(randomNumber);
      }
      return numbers;
    }
    
    const randomNumbers = generateRandomNumbers();
    const [index, setIndex] = useState(randomNumbers)

    useEffect(() => {
        setIndex(randomNumbers)
    },[produtos])

      const incrementNumbers = () => {
          const lastNumber = index[index.length - 1];
          if (lastNumber === produtos.length - 1) {
              setIndex([0, 1, 2]);
          } else {
              const updatedNumbers = index.map((number) => number + 1);
              setIndex(updatedNumbers);
          }
        };
        
      const decrementNumbers = () => {
          const firtNumber = index[0];
          if (firtNumber === 0) {
              setIndex([produtos.length - 3, produtos.length - 2, produtos.length - 1]);
          } else {
              const updatedNumbers = index.map((number) => number - 1);
              setIndex(updatedNumbers);
          }
        };
    

    return (
        <S.Container>
            {isMobile() ? <h2 className='title-interesse'>OUTRAS PEÇAS</h2> : <h2 className='title-interesse'><b>PEÇAS QUE TAMBÉM PODEM TE INTERESSAR</b></h2>}
                {isMobile() ? (
                    <S.Carrossel>
                        <CardProduto interesse produto={produtos[index[0]]} />
                        <CardProduto interesse produto={produtos[index[1]]} />
                        <CardProduto interesse produto={produtos[index[2]]} />
                    </S.Carrossel>
                ) : (
                    <S.Carrossel>
                        <i onClick={() => {
                            decrementNumbers()
                        }} style={{color: "#D6BF91", cursor:"pointer"}} className="ri-arrow-left-s-line ri-2x" />
                            <CardProduto produto={produtos[index[0]]} />
                            <CardProduto produto={produtos[index[1]]} />
                            <CardProduto produto={produtos[index[2]]} />
                        <i onClick={() => {
                            incrementNumbers()
                        }} style={{color: "#D6BF91", cursor:"pointer"}} className="ri-arrow-right-s-line ri-2x" />
                    </S.Carrossel>
                )}
        </S.Container>
    )
}

export default Interesse;