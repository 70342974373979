import styled from "styled-components";

export const Container = styled.div`
    padding: 80px 20px 20px 100px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    h2 {
        font-weight: 400;
    }
    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }

    @media (max-width: 1019px) {
        .content {
            grid-template-columns: 1fr;
        }
    }
    .base-info {
        display: flex;
        flex-direction: column;
    }
    .telefone, .email {
        display: flex;
        flex-direction: column;
    }
    .email {
        margin-top: 15px;
    }
`