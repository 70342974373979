/* eslint-disable jsx-a11y/alt-text */
import Header from '../../components/Header';
import * as S from './styles'
import { Produto as ProdutoDictionary } from '../../shared/utils/dictionary'
import { Breadcrumb, Modal, Select } from 'antd';
import Interesse from './Interesse';
import Contato from './Contato';
import { useEffect, useRef, useState } from 'react';
import { isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getProduto } from '../../shared/redux/selectors';
import axios from 'axios';
import { AppDispatch } from '../../App';
import { getProduto as getProdutoDispatch } from '../../shared/redux/slices/loja';
import Whats from '../../components/Whats';
import { postWhats } from '../../service/api';
import GuiaMedidas from '../../assets/images/guiaMedidas.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './modal.css';

const Produto: React.FC = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    const dispatch = useDispatch<AppDispatch>()
    const [ip, setIP] = useState()
    const [tamanhoSelect, setTamanho] = useState("")
    const [visible, setVisible] = useState(false);
    const [visibleImg, setVisibleImg] = useState(false);
    const [index, setIndex] = useState(0)
    const sliderRef = useRef<Slider>(null);

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    useEffect(() => {
        scrollToTop();
      }, []);

    const produto = useSelector(getProduto)
    const link = window.location.origin + window.location.pathname + window.location.search;
    function createMarkup(html: any) {
        return { __html: html };
      }

      useEffect(() => {
        dispatch(getProdutoDispatch(String(params.id)))
        axios.get(`${process.env.REACT_APP_API}produto/clique/${String(params.id)}`);
      },[dispatch, params.id])



      const incrementNumbers = () => {
        setIndex((prevIndex) => (prevIndex - 1 + produto[ProdutoDictionary.FOTOS].length) % produto[ProdutoDictionary.FOTOS].length);
      };
      
    const decrementNumbers = () => {
        setIndex((prevIndex) => (prevIndex + 1) % produto[ProdutoDictionary.FOTOS].length);
      };

      const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        rows: 1,
        centerPadding: '10px',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const tamanhos = produto.tamanhos?.split(" | ")
    const tamanhosOption = tamanhos?.map(item => ({
        value: item,
        label: item
      }))

    return (
        <S.Container>
            <Header />
            <Modal
                width={isMobile() ? "90vw" : "40vw"}
                open={visible}
                onCancel={() => {setVisible(false)}}
                footer={null}
            >
                <img style={{width: "-webkit-fill-available"}} src={GuiaMedidas} alt="Imagem" />
            </Modal>
            <Modal
                className="custom-modal"
                width={"100vw"}
                open={visibleImg}
                onCancel={() => {setVisibleImg(false)}}
                footer={null}
                style={isMobile() ? {} : {top: 0, margin: 0}}
            >
                <div style={isMobile() ? {display: "flex", flexDirection: "row", width: "90vw", margin: "auto", height: "60vh", justifyContent: "center", alignItems: "center"} : {display: "flex", flexDirection: "row", width: "90vw", margin: "auto", height: "calc(80vw / 2", justifyContent: "center", alignItems: "center"}}>
                    <i onClick={() => {
                            incrementNumbers()
                        }} style={{color: "#D6BF91", backgroundColor: "white", cursor:"pointer", position: "absolute", left: "10px" , zIndex: 999}} className="ri-arrow-left-s-line ri-2x" />
                    <img style={isMobile() ? {width: "auto", maxHeight: "50vh"} : {width: "auto", height: "calc(80vw / 2", objectFit: "cover"}} src={`${process.env.REACT_APP_API}${produto[ProdutoDictionary.FOTOS][index]?.[ProdutoDictionary.ARQUIVO]}`} alt="Imagem" />
                    <i onClick={() => {
                            decrementNumbers()
                        }} style={{color: "#D6BF91", backgroundColor: "white", cursor:"pointer", position: "absolute", right: "10px", zIndex: 999}} className="ri-arrow-right-s-line ri-2x" />
                </div>
            </Modal>
            {isMobile() ? null : (<Whats />)}
            <Breadcrumb className='breadcrumb' items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: produto?.[ProdutoDictionary.NOME],
                    },
                    ]} />
            <div>
                <S.Carrossel>
                    {isMobile() ? null : (
                        <>
                            <i onClick={() => sliderRef?.current?.slickPrev()} style={{color: "#D6BF91",  zIndex: 999, top: "calc(100vh / 2)", cursor:"pointer", position: "absolute", backgroundColor: "white", left: "30px"}} className="ri-arrow-left-s-line ri-2x" />
                            <i onClick={() => sliderRef?.current?.slickNext()}style={{color: "#D6BF91", cursor:"pointer", zIndex: 999, top: "calc(100vh / 2)", position: "absolute", backgroundColor: "white", right: "30px"}} className="ri-arrow-right-s-line ri-2x" />
                        </>
                    )}
                    <Slider ref={sliderRef} {...settings}>
                    {produto[ProdutoDictionary.FOTOS].map((item, i) => (
                            <div className='square'>
                                <img onClick={() => {setVisibleImg(true); setIndex(i)}} src={`${process.env.REACT_APP_API}${item?.[ProdutoDictionary.ARQUIVO]}`} />
                            </div>
                        ))}
                    </Slider>
                </S.Carrossel>
            </div>
            <S.Content>
               <div>
                    <S.Info>
                        <h1><b>{produto?.[ProdutoDictionary.NOME]}</b></h1>
                        <h2>{produto?.[ProdutoDictionary.DESCRICAO]}</h2>
                        <div className='especs'>
                            <span>
                                <h5>CATEGORIA</h5>
                                <h4>{produto?.[ProdutoDictionary.CATEGORIA][ProdutoDictionary.NOME_CATEGORIA]}</h4>
                            </span>
                            <span>
                                <h5>NOME</h5>
                                <h4>{produto?.[ProdutoDictionary.NOME]}</h4>
                            </span>
                            <span>
                                <h5>MATERIAL</h5>
                                <h4>{produto?.[ProdutoDictionary.MATERIAL][ProdutoDictionary.NOME_MATERIAL]}</h4>
                            </span>
                            <span>
                                <h5>PEDRA</h5>
                                <h4>{produto?.[ProdutoDictionary.TIPO_PEDRA][ProdutoDictionary.NOME_TIPO_PEDRA]}</h4>
                            </span>
                        </div>
                        <div className='tamanho'>
                                {!produto?.[ProdutoDictionary.TAMANHO]?.split(" | ").find(x => x === "Sem tamanho") ? (
                                    <>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}}>
                                        <h6 onClick={() => {
                                            setVisible(true)
                                        }}>Guia de tamanhos</h6>
                                    </div>
                                    <h3><b>Tamanho:</b> 
                                    <Select
                                        onChange={(event) => setTamanho(event)}
                                        defaultValue="Tamanho"
                                        bordered={false}
                                        dropdownStyle={{minWidth: 'fit-content'}}
                                        options={tamanhosOption}
                                    /></h3>
                                    </>
                                    ) : null}
                        </div>
                        <h3 style={{color: "#D6BF91", marginTop: "25px", marginBottom: "10px"}}>OBSERVAÇÕES</h3>
                        <div style={isMobile() ? {overflow: "hidden", width: "100%"} : {overflow: "hidden", width: "50vw"}}>
                            <p dangerouslySetInnerHTML={createMarkup(produto?.[ProdutoDictionary.OBSERVACAO])}></p>
                        </div>
                        <h3 style={{color: "#D6BF91", marginTop: "25px", marginBottom: "10px"}}>COMPARTILHAR</h3>
                        <div className='compartilhar'>
                            <i style={{color: "#646161"}} className="ri-share-line ri-xl"></i>
                            <i onClick={() => {
                                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`, '_blank')
                            }} style={{color: "#0057FF", cursor: "pointer"}} className="ri-facebook-fill ri-xl"></i>
                            <i onClick={() => {
                                window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(link)}`, '_blank')
                            }} style={{color: "#00C2FF", cursor: "pointer"}} className="ri-twitter-fill ri-xl"></i>
                            <i onClick={() => {
                                if(!ip) {
                                    return
                                    } else {
                                    postWhats({
                                        ip: ip
                                    })
                                    }
                                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`, '_blank')
                            }} style={{color: "#20B038", cursor: "pointer"}} className="ri-whatsapp-line ri-xl"></i>
                        </div>
                    </S.Info>
                    {isMobile() ? (
                        <S.Contato>
                        <Contato tamanho={tamanhoSelect} produto={produto} />
                       </S.Contato>
                    ) : (
                        <S.Interesse>
                            <Interesse />
                        </S.Interesse>
                    )}
                </div>
                {isMobile() ? (
                    <S.Interesse>
                    <Interesse />
                </S.Interesse>
                ) : (
                <S.Contato>
                    <Contato tamanho={tamanhoSelect} produto={produto} />
                </S.Contato>
                )}
            </S.Content>
        </S.Container>
    )
}

export default Produto;