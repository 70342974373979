import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    text-align: center;
    margin-top: 30px;
    h3{
        margin-top: 10px;
        font-weight: 500;
        letter-spacing: 0.1em;
        font-variant: all-small-caps;
        font-size: 25px;
        text-align: center;
        width: 100%;
    }
    h4 {
        font-weight: 500;
        font-size: 15px;
    }
    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    hr {
        width: 100px;
        border: 1px solid #D6BF91;
        background-color: #D6BF91;
    }
`

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 30px 20px 30px;
    gap: 10px;

    @media (max-width: 991px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`