import Header from '../../components/Header';
import * as S from './styles'
import { Breadcrumb } from 'antd';
import { Banner } from '../../shared/utils/dictionary';
import { isMobile, scrollToTop } from '../../shared/utils/javascript';
import { useDispatch, useSelector } from 'react-redux';
import { getBanner } from '../../shared/redux/selectors';
import { useEffect } from 'react';
import { AppDispatch } from '../../App';
import { getBanners } from '../../shared/redux/slices/loja';

const Sobre: React.FC = () => {

    const imagens = useSelector(getBanner)
    const dispatch = useDispatch<AppDispatch>()
    function createMarkup(html: any) {
        return { __html: html };
      }

    useEffect(() => {
        dispatch(getBanners())
    },[])

    useEffect(() => {
        scrollToTop();
      }, []);
    
    const pos6Mobile = imagens.filter((x) => x[Banner.POSICAO] === "6")?.filter((x) => x[Banner.MOBILE] === "MOBILE")[0]
    const pos7Mobile = imagens.filter((x) => x[Banner.POSICAO] === "7")?.filter((x) => x[Banner.MOBILE] === "MOBILE")[0]
    const pos6 = imagens.filter((x) => x[Banner.POSICAO] === "6")?.filter((x) => x[Banner.MOBILE] === "DESKTOP")[0]
    const pos7 = imagens.filter((x) => x[Banner.POSICAO] === "7")?.filter((x) => x[Banner.MOBILE] === "DESKTOP")[0]

    return (
        <S.Container>
            <Header />
            <S.Content>
                <Breadcrumb className='breadcrumb' items={[
                    {
                        title: 'Início',
                    },
                    {
                        title: 'Sobre a empresa',
                    }
                    ]} />
                    <div className='container'>
                        {isMobile() ? (
                            <>
                                <span>
                                    <h2>Um pedaço da nossa história</h2>
                                    <p className='texto' dangerouslySetInnerHTML={createMarkup(pos6Mobile?.[Banner.DESCRICAO])}></p>
                                </span>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${pos6Mobile?.[Banner.FOTO][Banner.ARQUIVO]}`} alt={pos6?.[Banner.DESCRICAO]} />
                            </>
                        ) : (
                            <>
                                <img className='imagem' src={`${process.env.REACT_APP_API}${pos6?.[Banner.FOTO][Banner.ARQUIVO]}`} alt={pos6?.[Banner.DESCRICAO]} />
                                <span>
                                    <h2>Sobre a empresa</h2>
                                    <p className='texto' dangerouslySetInnerHTML={createMarkup(pos6?.[Banner.DESCRICAO])}></p>
                                </span>
                            </>
                        )}
                    </div>
                    <div className='container'>
                        <span style={!isMobile() ? {} : {alignItems: 'end'}}>
                            <h2>As nossas criações</h2>
                            <p className='texto' dangerouslySetInnerHTML={isMobile() ? createMarkup(pos7Mobile?.[Banner.DESCRICAO]) : createMarkup(pos7?.[Banner.DESCRICAO])}></p>
                        </span>
                        <img className='imagem' src={isMobile() ? `${process.env.REACT_APP_API}${pos7Mobile?.[Banner.FOTO][Banner.ARQUIVO]}` : `${process.env.REACT_APP_API}${pos7?.[Banner.FOTO][Banner.ARQUIVO]}`} alt={pos7?.[Banner.DESCRICAO]} />
                    </div>
            </S.Content>
        </S.Container>
    )
}

export default Sobre;