import { useDispatch } from 'react-redux'
import { Categoria } from '../../shared/types'
import { Categoria as CategoriaDictionary } from '../../shared/utils/dictionary'
import * as S from './styles'
import { AppDispatch } from '../../App'
import { setSelectFiltro } from '../../shared/redux/slices/loja'
import { useNavigate } from 'react-router-dom'


type TypeCardCategoria = {
    categoria: Categoria
}
const CardCategoria = ({categoria} : TypeCardCategoria) => {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    return (
        <S.Container onClick={() => {
            dispatch(setSelectFiltro({valor: categoria.nome, tipo: "categoria"}))
            navigate("/estoque")
        }}>
            <span>
                <h3>{categoria?.[CategoriaDictionary.NOME]}</h3>
                <img src={`${process.env.REACT_APP_API}${categoria?.[CategoriaDictionary.FOTO][CategoriaDictionary.ARQUIVO]}`} alt={categoria?.[CategoriaDictionary.NOME]} />
            </span>
        </S.Container>
    )
}

export default CardCategoria;