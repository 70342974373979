import { Routes as RoutesDOM, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Estoque from "../pages/Estoque";
import Produto from "../pages/Produto";
import Contato from "../pages/Contato";
import Sobre from "../pages/Sobre";
import Servicos from "../pages/Servicos";
import Cotar from "../pages/Cotar";
import Cookies from "js-cookie";
import { Login } from "../pages/Admin/Login";
import { Admin } from "../pages/Admin";
import { Visitas } from "../pages/Admin/Visitas";
import { Banner } from "../pages/Admin/Banner";
import { Configuracoes } from "../pages/Admin/Configuracoes";
import { WhatsApp } from "../pages/Admin/Whatsapp";
import { Emails } from "../pages/Admin/Emails";
import { Categoria } from "../pages/Admin/Categoria";
import { Tamanho } from "../pages/Admin/Tamanho";
import { Material } from "../pages/Admin/Material";
import { Pedra } from "../pages/Admin/Pedra";
import { Campanha } from "../pages/Admin/Campanha";
import { Produto as ProdutoAdmin } from "../pages/Admin/Produtos";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../App";
import { useEffect } from "react";
import { getLoja } from "../shared/redux/slices/loja";

const Routes = () => {
  const login = Cookies.get('auth');
  
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getLoja())
  },[])
  
  return (
    <RoutesDOM>
      {/* <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<PageNotFound />} /> */}
      <Route path="/" element={<Home />}/>
      <Route path="/estoque" element={<Estoque />}/>
      <Route path="/produto" element={<Produto />}/>
      <Route path="/contato" element={<Contato />}/>
      <Route path="/sobre-empresa" element={<Sobre />}/>
      <Route path="/servicos" element={<Servicos />}/>
      <Route path="/cotar" element={<Cotar />}/>

      <Route path="/admin/" element={<Login />}/>
      <Route path="/admin/home" element={<Admin />}/>
      <Route path="/admin/visitas" element={login ? <Visitas /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/banner" element={login ? <Banner /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/configuracoes" element={login ? <Configuracoes /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/whatsapp" element={login ? <WhatsApp /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/emails" element={login ? <Emails /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/categorias" element={login ? <Categoria /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/tamanhos" element={login ? <Tamanho /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/materiais" element={login ? <Material /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/pedras" element={login ? <Pedra /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/campanha" element={login ? <Campanha /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/produtos" element={login ? <ProdutoAdmin /> : <Navigate to="/admin/" />}/>
    </RoutesDOM>
  );
};

export default Routes;
