import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from './HeaderAdmin'
import { Line } from "react-chartjs-2";
import * as S from './styles'
import { AppDispatch } from '../../App'
import { useEffect, useState } from 'react'
import { AsideAdmin } from './AsideAdmin'

import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getVisitas } from '../../shared/redux/slices/loja'
import { getVisitas as getVisitasSelector } from '../../shared/redux/selectors'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const Admin = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [labelChart, setLabelChart] = useState<any>([])
    const [dataChart, setDataChart] = useState<any>([])
    const visitas = useSelector(getVisitasSelector)
    const login = Cookies.get('auth');
    const navigate = useNavigate()

    useEffect(()=> {
        if(!login) {
            navigate('/admin')
        }
        dispatch(getVisitas())
    },[login])

    const filterData = visitas.filter(obj => {
        const currentDate = new Date();
        const objDate = new Date(obj.data);
        const differenceInTime = currentDate.getTime() - objDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 15;
      });

    useEffect(() => {
        const accessCounts : { [date: string]: number } = {};
        filterData.forEach(obj => {
        const date = obj.data;
        accessCounts[date] = accessCounts[date] ? accessCounts[date] + 1 : 1;
        });
        setLabelChart(Object.keys(accessCounts))
        setDataChart(Object.values(accessCounts))

    },[visitas])

    const data = {
        labels: labelChart,
        datasets: [
          {
            label: "Acessos",
            data: dataChart,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    return (
        <S.Container>

                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title'>
                        <i className="ri-home-2-line ri-3x"></i>
                        <h1>Painel</h1>
                    </div>
                    <div className='title-bar'>
                        <i className="ri-line-chart-line ri-2x"></i>
                        <h3>Visitas dos últimos dias</h3>
                    </div>
                    <div className='inside-bar'>
                        <Line style={{maxHeight: 200}} data={data}/>
                    </div>
        </S.Container>
    )
}