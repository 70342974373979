import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Provider, useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./shared/redux/slices";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css'
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { postVisita } from "./service/api";
import axios from "axios";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const store = configureStore({reducer: rootReducer});
export type AppDispatch = typeof store.dispatch

function App() {

  const [ip, setIP] = useState()

  const getData = async () => {

    axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIP(response.data.ip);
    })
    .catch(error => {
      console.log(error);
    });
    
    };
      
  useEffect(()=> {
    getData()
    if(!ip) return
    postVisita({
      ip: ip
  })
  },[ip])

  return (
    <div className="App">
      <ToastContainer style={{zIndex: 9999999}} />
      <Provider store={store}>
        <BrowserRouter>
        <div className="container">
          <Routes />
        </div>
          {!window.location.pathname.includes('admin') ? (<Footer />): null}
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
