import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;

    .whats {
        cursor: pointer;
        border-radius: 100%;
        background-color: #07DA4D;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            color: white;
            border-radius: 100%;
            padding: 5px 2px;
        }
    }
`
