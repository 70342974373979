import { Reducer, combineReducers } from "redux";
import { RootState } from "../../types";

import lojaReducer from "./loja";

const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  lojaState: lojaReducer,
});

export default rootReducer;
