import styled from 'styled-components';

export const Container = styled.div`
    .breadcrumb {
        padding: 20px 100px;
    }
    @media (max-width: 991px) {
        .breadcrumb {
            padding: 10px 0 25px 10px;
        }
    }
`
export const Content = styled.div`
    h1 {
        font-weight: 500;
        font-size: 20px;
    }
    h2 {
        font-weight: 500;
        font-size: 15px;
        text-align: center;
    }
    h3 {
        color: #025773;
        font-weight: 400;
        font-size: 17px;
    }
    h4 {
        font-weight: 500;
        font-size: 13px;
        max-width: 260px;
        cursor: pointer;
    }
    h5 {
        font-weight: 500;
        font-size: 25px;
        text-align: start;
        font-variant: small-caps;
    }
    h6 {
        font-size: 13px;
        margin-top: 15px;
        font-weight: 500;
        text-align: justify;
        margin-bottom: 20px;
    }
    hr {
        border: 1px solid #D6BF91;
        width: 100px;
    }
    .titulo {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }
    .grid-card {
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        gap: 60px;
        justify-content: center;
        padding: 0 10px;
    }
    .card {
        display: grid;
        grid-template-rows: 5fr 1fr 1fr;
        gap: 15px;
        text-align: center;
    }
    .icon {
        background: #FFFFFF;
        border: 1px solid #D6BF91;
        border-radius: 5px;
        padding: 30px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 10px;
    }
    .container {
        padding: 40px 50px 40px 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        gap: 20px;
        align-items: center;
    }
    .imagem {
        width: 50vw;
        height: auto;
    }
    p {
        color: black;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 15px;
        max-width: 100%;
        text-align: justify;
    }
    .background {
        padding: 10px;
        margin-top: 40px;
        margin-bottom: 40px;
        background: linear-gradient(0deg, #D9D9D9 0%, #FAFAFA 100%);
    }
    h2 {
        font-weight: 500;
        font-size: 20px;
        font-variant: small-caps;
        text-align: start;
        margin-bottom: 10px;
    }

    @media (max-width: 991px) {
        .titulo {
            text-align: center;
            padding: 0 10px;
            >h1 {
                letter-spacing: 0.1em;
            }
            >h2 {
                text-align: center;
            }
        }
        .grid-card {
            justify-content: start;
            width: 100%;
            box-sizing: border-box;
            overflow: auto;
            gap: 20px;
            padding-bottom: 15px;
        }
        .card {
            display: flex;
            flex-direction: column;
        }
        .container {
            flex-direction: column;
            padding: 20px 0 0 0;
            margin-bottom: 30px;
        }
        .texto {
            max-width: 100%;
        }
        p {
            max-width: 100%;
        }
        .imagem {
            width: 96vw;
        }
        .icon {
            width: max-content;
        }
    }
`