import styled from "styled-components";

export const Container = styled.div`
    padding: 80px 20px 20px 100px;
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-container {
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 1019px) {
        .title-container {
            flex-direction: column;
        }
    }
    .banner-table-container {
        overflow-x: auto;
        white-space: nowrap;
    }
    .title {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
    }
    .title-table {
        border-bottom: 1px solid black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        align-items: center;
        overflow-x: auto;
        min-width: 600px;
    }
    .actions {
        display: flex;
        justify-content: center;
    }
    .ant-col {
        max-width: 100%;
        overflow: hidden;
    }
    img {
        max-height: 100px;
    }

`