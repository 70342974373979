import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .banner2 {
        padding: 80px 10px 0 10px;
        border-radius: 5px;
    }
    .banner3, .banner4, .banner5 {
        width: 50vw;
    }
    .banner {
        padding: 40px 50px 40px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        text-align: justify;
    }
    .background {
        background: linear-gradient(0deg, #D9D9D9 0%, #FAFAFA 100%);
    }
    h2 {
        font-weight: 500;
        font-size: 20px;
        font-variant: small-caps;
        text-align: start;
        margin-bottom: 10px;
    }
    p {
        color: black;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 15px;
        max-width: 100%;
        text-align: justify;
    }
    .button {
        margin-top: 15px;
        font-weight: 500;
        cursor: pointer;
    }

    @media (max-width: 991px) {
        .background {
            padding: 0 0 50px 0;
        }
        .banner {
            flex-direction: column;
            padding: 50px 15px 0 15px;
        }
        .banner3, .banner4, .banner5 {
            width: -webkit-fill-available;
        }
        p {
            max-width: 100%;
        }
    }
`