import { Button, Checkbox, Collapse, Dropdown, MenuProps, Select } from 'antd';
import * as S from './styles'
import { isMobile } from '../../../shared/utils/javascript';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getProdutos, getProdutosFiltro, getSelect, getSelects } from '../../../shared/redux/selectors';
import { 
    Categoria as CategoriaDictionary, 
    Material as MaterialDictionary,
    Tamanho as TamanhoDictionary,
    TipoPedra as TipoPedraDictionary,
} from '../../../shared/utils/dictionary';
import { AppDispatch } from '../../../App';
import { clearSelect, filtroBusca, filtroCampanha, oderBy, setSelectFiltro } from '../../../shared/redux/slices/loja';

const { Panel } = Collapse;

const Filtro = () => {

    const [openFilter, setOpenFilter] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState<string>("")
    const selectSelector = useSelector(getSelects)
    const valueSelect = useSelector(getSelect)
    const produtos = useSelector(getProdutos)
    const produtosSelect = useSelector(getProdutosFiltro)
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);

    const handleCollapseChange = (keys: string | string[]) => {
        setExpandedKeys(Array.isArray(keys) ? keys : [keys]);
    };

    const handleChangeSelect = (event: any, tipo: string) => {
      dispatch(setSelectFiltro({valor: event, tipo: tipo}))
    }

    useEffect(() => {
      if(params.busca && produtos.length === produtosSelect.length) {
        dispatch(filtroBusca(params.busca))
      }
      if(params.categoria && produtos.length === produtosSelect.length) {
          handleChangeSelect(params.categoria, "categoria")
      }
      if(params.campanha && produtos.length === produtosSelect.length) {
        dispatch(filtroCampanha(params.campanha))
      }
    },[params])

    const categoria = selectSelector.categoria.map(item => ({
        value: item[CategoriaDictionary.NOME],
        label: item[CategoriaDictionary.NOME]
      }))
    const material = selectSelector.material.map(item => ({
        value: item[MaterialDictionary.NOME],
        label: item[MaterialDictionary.NOME]
      }))
    const tipoPedra = selectSelector.tipoPedra.map(item => ({
        value: item[TipoPedraDictionary.NOME],
        label: item[TipoPedraDictionary.NOME]
      }))
    const tamanho = selectSelector.tamanho.map(item => ({
        value: item[TamanhoDictionary.TAMANHO],
        label: item[TamanhoDictionary.TAMANHO]
      }))

    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <p>Por relevância</p>
          ),
          onClick: (event) => {
            dispatch(oderBy(Number(event.key)))
          }
        },
        {
          key: '2',
          label: (
            <p>A-Z</p>
          ),
          onClick: (event) => {
            dispatch(oderBy(Number(event.key)))
          }
        },
        {
          key: '3',
          label: (
            <p>Mais recentes</p>
          ),
          onClick: (event) => {
            dispatch(oderBy(Number(event.key)))
          }
        },
      ];

    return (
        <S.Container>
            {openFilter && (
            <S.OpenFilter>
              <div className='gray-opacity'>
                <div className='content' style={{overflowY: "auto"}}>
                    <i onClick={() => setOpenFilter(false)} id='close' className="ri-close-line ri-2x"></i>
                    <Search className='input-search' onChange={(value) => {
                      setSearchValue(value.target.value)
                    }} placeholder="Busque a sua joia" onSearch={(value) => {
                          navigate(`/estoque?busca=${value}`)
                          dispatch(clearSelect())
                          dispatch(filtroBusca(value))
                          setOpenFilter(false)
                        }} />
                        <Collapse 
                            expandIconPosition="right" 
                            expandIcon={({ isActive }) =>
                                isActive ? <i className="ri-arrow-up-s-line ri-2x"></i> : <i className="ri-arrow-down-s-line ri-2x"></i>
                            }
                            activeKey={expandedKeys}
                            onChange={handleCollapseChange}
                            >
                            <Panel header="Categoria" key="1">
                                {categoria.map((x) => (
                                    <Checkbox
                                    className="medium-checkbox"
                                    checked={valueSelect.categoria === x.label}
                                    onChange={() => handleChangeSelect(x.label, "categoria")}
                                    >{x.label}</Checkbox>
                                ))}
                            </Panel>
                            <Panel header="Material" key="2">
                                {material.map((x) => (
                                    <Checkbox
                                    className="medium-checkbox"
                                    checked={valueSelect.material === x.label}
                                    onChange={() => handleChangeSelect(x.label, "material")}
                                    >{x.label}</Checkbox>
                                ))}
                            </Panel>
                            <Panel header="Tipo pedra" key="3">
                                {tipoPedra.map((x) => (
                                    <Checkbox
                                    className="medium-checkbox"
                                    checked={valueSelect.tipoPedra === x.label}
                                    onChange={() => handleChangeSelect(x.label, "tipoPedra")}
                                    >{x.label}</Checkbox>
                                ))}
                            </Panel>
                            <Panel header="Tamanho" key="4">
                                {tamanho.map((x) => (
                                    <Checkbox
                                    className="medium-checkbox"
                                    checked={valueSelect.tamanho === x.label}
                                    onChange={() => handleChangeSelect(x.label, "tamanho")}
                                    >{x.label}</Checkbox>
                                ))}
                            </Panel>
                        </Collapse>
                        <div className='button-grid'>
                            <Button onClick={() => {
                                dispatch(clearSelect())
                                navigate('/estoque')
                              }} className='limpar'>Limpar</Button>
                            <Button onClick={()=> {
                              if(searchValue.length > 0) {
                                navigate(`/estoque?busca=${searchValue}`)
                                setSearchValue("")
                                dispatch(clearSelect())
                                dispatch(filtroBusca(searchValue))
                                setOpenFilter(false)
                              }
                              setOpenFilter(false)
                              }} className='buscar'>Buscar</Button>
                        </div>
                </div>
              </div>
            </S.OpenFilter>)}
            {isMobile() ? (
                <div className='div-buttons'>
                    <Button onClick={() => {setOpenFilter(true)}} htmlType="button">Filtrar</Button>
                    <Dropdown menu={{ items }}>
                            <Button htmlType="button">Ordenar por</Button>
                    </Dropdown>
                    
                </div>
            ) : (
                <>
                <h3>Filtrar por:</h3>
                <Select
                    onChange={(event) => handleChangeSelect(event, "categoria")}
                    defaultValue="Categoria"
                    bordered={false}
                    dropdownStyle={{minWidth: 'fit-content'}}
                    value={valueSelect.categoria !== "" ? valueSelect.categoria : "Categoria"}
                    options={categoria}
                    suffixIcon={<i className="ri-arrow-drop-down-line ri-lg" style={{color: "black", fontSize: "20px"}} />}
                />
                <Select
                    onChange={(event) => handleChangeSelect(event, "material")}
                    defaultValue="Material"
                    bordered={false}
                    value={valueSelect.material !== "" ? valueSelect.material : "Material"}
                    options={material}
                    suffixIcon={<i className="ri-arrow-drop-down-line ri-lg" style={{color: "black", fontSize: "20px"}} />}
                />
                <Select
                    onChange={(event) => handleChangeSelect(event, "tipoPedra")}
                    defaultValue="Tipo de pedra"
                    bordered={false}
                    value={valueSelect.tipoPedra !== "" ? valueSelect.tipoPedra : "Tipo de pedra"}
                    options={tipoPedra}
                    suffixIcon={<i className="ri-arrow-drop-down-line ri-lg" style={{color: "black", fontSize: "20px"}} />}
                />
                <Select
                    onChange={(event) => handleChangeSelect(event, "tamanho")}
                    defaultValue="Tamanho"
                    bordered={false}
                    value={valueSelect.tamanho !== "" ? valueSelect.tamanho : "Tamanho"}
                    options={tamanho}
                    suffixIcon={<i className="ri-arrow-drop-down-line ri-lg" style={{color: "black", fontSize: "20px"}} />}
                />
            </>
            )}
            
        </S.Container>
    )
}

export default Filtro;

