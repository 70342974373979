import * as S from './styles';
import { Loja as LojaDictionary } from '../../shared/utils/dictionary';
import { useSelector } from 'react-redux';
import { getLoja } from '../../shared/redux/selectors';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { postWhats } from '../../service/api';

const Whats = () => {
    const link = window.location.origin + window.location.pathname + window.location.search;
    const loja = useSelector(getLoja)
    const [ip, setIP] = useState()

    const getData = async () => {
  
      axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIP(response.data.ip);
      })
      .catch(error => {
        console.log(error);
      });
      
    };
        
    useEffect(()=> {
      getData()
    },[ip])

    return (
        <S.Container>
            <div onClick={() => {
                    if(!ip) {
                    return
                    } else {
                    postWhats({
                        ip: ip
                    })
                    }
                window.open(`https://api.whatsapp.com/send?phone=+55${loja[LojaDictionary.WHATS]}&text=Olá, tudo bem? Estou acessando o site da *Joalheria Carvalho* e gostaria de mais informações sobre esta jóia: ${link}.`, '_blank')}
            } className='whats'><i className="ri-whatsapp-line ri-xl"></i></div>
        </S.Container>
    )
}

export default Whats;