import axios from "axios";
import Cookies from "js-cookie";

const apiAdmin = axios.create({
  baseURL: `${process.env.REACT_APP_API}/`,
});

const usuarioCookies = Cookies.get('auth');

export const postLogin = (data: {usuario: string, senha: string}) => {
  try {
    return apiAdmin.post<any>(`/login/entrar`, data);
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteProduto = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/produto/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postProduto = (data: {nome: string, descricao: string, observacao: string, idMarca: number, idCategoria: number, idTipoPedra: number, idMaterial: number, idCampanha?: number, tamanhos: string[]}) => {
  try {
    return apiAdmin.post<{idProduto: number}>(`/produto`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editProduto = (data: {nome: string, descricao: string, observacao: string, idMarca: number, idCategoria: number, idTipoPedra: number, idMaterial: number, idCampanha?: number, tamanhos: string[]}, id:number) => {
  try {
    return apiAdmin.put<any>(`/produto/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteCategoria = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/categoria/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postCategoria = (data: {nome: string}) => {
  try {
    return apiAdmin.post<any>(`/categoria`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editCategoria = (data: {nome: string}, id:number) => {
  try {
    return apiAdmin.put<any>(`/categoria/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteBanner = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/banner/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postBanner = (data: any) => {
  try {
    return apiAdmin.post<any>(`/banner/`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editBanner = (data: any, id:number) => {
  try {
    return apiAdmin.put<any>(`/banner/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteTamanho = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/tamanho/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postTamanho = (data: {tamanho: string}) => {
  try {
    return apiAdmin.post<any>(`/tamanho`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editTamanho = (data: {tamanho: string}, id:number) => {
  try {
    return apiAdmin.put<any>(`/tamanho/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteMaterial = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/material/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postMaterial = (data: {nome: string, texto: string}) => {
  try {
    return apiAdmin.post<any>(`/material`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editMaterial = (data: {nome: string, texto: string}, id:number) => {
  try {
    return apiAdmin.put<any>(`/material/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deletePedra = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/tipopedra/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postPedra = (data: {nome: string, texto: string}) => {
  try {
    return apiAdmin.post<any>(`/tipopedra`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editPedra = (data: {nome: string, texto: string}, id:number) => {
  try {
    return apiAdmin.put<any>(`/tipopedra/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteCampanha = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/campanha/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postCampanha = (data: {nome: string, dataInicio: string, dataFim: string, situacao: boolean}) => {
  try {
    return apiAdmin.post<any>(`/campanha`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const editCampanha = (data: {nome: string, dataInicio: string, dataFim: string, situacao: boolean}, id:number) => {
  try {
    return apiAdmin.put<any>(`/campanha/${id}`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postConfig = (data: any) => {
  try {
    return apiAdmin.put<any>(`/loja`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postTelefone = (data: any) => {
  try {
    return apiAdmin.post<any>(`/telefone`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteTelefone = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/telefone/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const postEmail = (data: any) => {
  try {
    return apiAdmin.post<any>(`/email`, data, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const deleteEmail = (data: string) => {
  try {
    return apiAdmin.delete<any>(`/email/${data}`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getEmailsRecebidos = () => {
  try {
    return apiAdmin.get<any>(`/emailRecebidos/`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getEmailsRecebidosNewsLetter = () => {
  try {
    return apiAdmin.get<any>(`/newsletter/`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getWhatsCliques = () => {
  try {
    return apiAdmin.get<any>(`/registro/whatsapp`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getVisitas = () => {
  try {
    return apiAdmin.get<any>(`/registro/visita`, {headers:{
      'token': `${usuarioCookies}`
    }});
  } catch (error) {
    console.log(error)
    throw error
  }
};

