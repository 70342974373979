import * as S from './styles'
import { useNavigate } from 'react-router-dom'

export const AsideAdmin = () => {
    const navigate = useNavigate()
    return (
        <S.Container>
            <div onClick={() => {
                navigate('/admin/home')
            }}>
                <i className="ri-home-2-line ri-lg"></i>
                <h4>Home</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/visitas')
            }}>
                <i className="ri-line-chart-line ri-lg"></i>
                <h4>Visitas</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/banner')
            }}>
                <i className="ri-image-edit-line ri-lg"></i>
                <h4>Banner</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/configuracoes')
            }}>
                <i className="ri-tools-line ri-lg"></i>
                <h4>Configuração</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/whatsapp')
            }}>
                <i className="ri-whatsapp-line ri-lg"></i>
                <h4>WhatsApp</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/emails')
            }}>
                <i className="ri-mail-line ri-lg"></i>
                <h4>Emails</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/produtos')
            }}>
                <i className="ri-shopping-bag-line ri-lg"></i>
                <h4>Produtos</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/categorias')
            }}>
                <i className="ri-folder-line ri-lg"></i>
                <h4>Categorias</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/tamanhos')
            }}>
                <i className="ri-font-size ri-lg"></i>
                <h4>Tamanhos</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/materiais')
            }}>
                <i className="ri-bubble-chart-line ri-lg"></i>
                <h4>Materiais</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/pedras')
            }}>
                <i className="ri-vip-diamond-line ri-lg"></i>
                <h4>Pedras</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/campanha')
            }}>
                <i className="ri-calendar-2-line ri-lg"></i>
                <h4>Campanha</h4>
            </div>
            
        </S.Container>
    )
}