import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../HeaderAdmin'
import * as S from './styles'
import { AppDispatch } from '../../../App'
import { AsideAdmin } from '../AsideAdmin'
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Select, Switch, Upload, message } from 'antd'
import { getProdutos as getProdutosSelector, getCampanhas as getCampanhasSelector, getCategorias as getCategoriasSelector, getMateriais as getMateriaisSelector, getTipoPedras as getTipoPedrasSelector, getTamanhos as getTamanhosSelector } from '../../../shared/redux/selectors'
import { Produto as ProdutoDictionary, Categoria as CategoriaDictionary, Material as MaterialDictionary, Tamanho as TamanhoDictionary, Campanha as CampanhaDictionary, TipoPedra as TipoPedraDictionary } from '../../../shared/utils/dictionary'
import { getCampanhas, getCategorias, getMateriais, getProdutos, getTamanhos, getTipoPedras } from '../../../shared/redux/slices/loja'
import { useEffect, useState } from 'react'
import { deleteProduto, editProduto, postProduto } from '../../../service/apiAdmin'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'

export const Produto = () => {
    const dispatch = useDispatch<AppDispatch>()
    const produtos = useSelector(getProdutosSelector)
    const campanhas = useSelector(getCampanhasSelector)
    const categorias = useSelector(getCategoriasSelector)
    const materiais = useSelector(getMateriaisSelector)
    const tipoPedras = useSelector(getTipoPedrasSelector)
    const tamanhos = useSelector(getTamanhosSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imagens, setImagens] = useState<any>([]);
    const [isModalEditarOpen, setIsModalEditarOpen] = useState(false);
    const [editObj, setEditObj] = useState<any>(null);
    const [imagemId, setImagemId] = useState<number[]>([]);
    const usuarioCookies = Cookies.get('auth');
    const [form] = Form.useForm();

    const categoria = categorias.map(item => ({
        value: item[CategoriaDictionary.ID],
        label: item[CategoriaDictionary.NOME]
      }))
    const material = materiais.map(item => ({
        value: item[MaterialDictionary.ID],
        label: item[MaterialDictionary.NOME]
      }))
    const tipoPedra = tipoPedras.map(item => ({
        value: item[TipoPedraDictionary.ID],
        label: item[TipoPedraDictionary.NOME]
      }))
    const tamanho = tamanhos.map(item => ({
        value: item[TamanhoDictionary.TAMANHO],
        label: item[TamanhoDictionary.TAMANHO]
      }))
    tamanho.unshift({ value: "Sem tamanho", label: 'Sem tamanho' }); 
    const campanha = campanhas.map(item => ({
        value: item[CampanhaDictionary.ID],
        label: item[CampanhaDictionary.NOME]
    }))
    campanha.unshift({ value: 0, label: 'Sem campanha' }); 

    useEffect(() => {
        dispatch(getProdutos())
        dispatch(getCampanhas())
        dispatch(getCategorias())
        dispatch(getMateriais())
        dispatch(getTipoPedras())
        dispatch(getTamanhos())
    },[])

        const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <i className="ri-pencil-line"></i>
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <i style={{color: "red"}} className="ri-delete-bin-6-line"></i>
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];
    
      const handleCancel = () => {
        if(isModalEditarOpen) {
            window.location.reload()
        }
          form.resetFields();
        setIsModalOpen(false);
        setIsModalEditarOpen(false);
      };

      const onFinish = async (values: any) => {
        const data = {
            nome: values.nome,
            descricao: values.descricao,
            observacao: values.observacao,
            idMarca: 1,
            situacao: values.situacao === undefined ? true : !!values.situacao,
            idCategoria: Number(values.categoria) ? values.categoria : categoria.find(x => x.label === values.categoria)?.value,
            idTipoPedra: Number(values.tipoPedra) ? values.tipoPedra : tipoPedra.find(x => x.label === values.tipoPedra)?.value,
            idMaterial: Number(values.material) ? values.material : material.find(x => x.label === values.material)?.value,
            idCampanha: values.campanha === 0 ? null : Number(values.campanha) ? values.campanha : campanha.find(x => x.label === values.campanha)?.value, 
            tamanhos: values.tamanho,
            preco: Number(values.preco),
        }

        if(isModalEditarOpen) {
            try {
                await editProduto(data, editObj[ProdutoDictionary.ID])
                form.resetFields();
                setIsModalOpen(false)
                setIsModalEditarOpen(false)
                window.location.reload()
            } catch {
                toast.error("Ocorreu um erro ao criar produto, tente novamente mais tarde ou entre em contato com nosso suporte.")
            }
        } else {
            try {
                if (imagens.length < 3) {
                  toast.error("Insira no mínimo 3 imagens");
                  return;
                }
                
                // Primeiro, faz a requisição para criar o produto
                const response = await postProduto(data);
                const idProduto = response.data.idProduto;
              
                // Preparar as requisições de upload das imagens
                const uploadPromises = imagens.map(async (element: any) => {
                  const formData = new FormData();
                  formData.append('file', element);
              
                  try {
                    await axios.post(`${process.env.REACT_APP_API}produto/imagem/${idProduto}`, formData, {
                      headers: {
                        'token': usuarioCookies,
                        'Content-Type': 'multipart/form-data'
                      }
                    });
                  } catch (error) {
                    message.error('Erro ao salvar a imagem');
                  }
                });
              
                // Aguardar todas as requisições de upload serem concluídas
                await Promise.all(uploadPromises);
              
                // Após todas as requisições, resetar o formulário e recarregar a página
                form.resetFields();
                setIsModalOpen(false);
                setImagens([]);
                window.location.reload();
              } catch (error) {
                toast.error("Ocorreu um erro ao criar produto, tente novamente mais tarde ou entre em contato com nosso suporte.");
              }
            }              
      };

      const handleUpload = async (file: any) => {
        setImagens([...imagens, file])
        return false;
      };

      const handleSelectAll = () => {
        const allItems = tamanhos.map((option) => option.tamanho);
        form.setFieldsValue({tamanho: allItems})
      };

      const handleRemove = (file: any) => {
        //TODO fazer remover img
        if (file.response && file.response.id) {
          setImagemId(imagemId.filter((id) => id !== file.response.id));
        }
      };

      useEffect(() => {
        if (isModalEditarOpen && editObj) {
            setImagemId(editObj?.[ProdutoDictionary.FOTOS])
          form.setFieldsValue({
            nome: editObj[ProdutoDictionary.NOME],
            preco: editObj[ProdutoDictionary.PRECO],
            descricao: editObj[ProdutoDictionary.DESCRICAO],
            observacao: editObj[ProdutoDictionary.OBSERVACAO],
            categoria: editObj[ProdutoDictionary.CATEGORIA][CategoriaDictionary.NOME],
            material: editObj[ProdutoDictionary.MATERIAL][MaterialDictionary.NOME],
            tipoPedra: editObj[ProdutoDictionary.TIPO_PEDRA][TipoPedraDictionary.NOME],
            tamanho: editObj[ProdutoDictionary.TAMANHO]?.split(" | "),
            campanha: editObj[ProdutoDictionary.CAMPANHA]?.[CampanhaDictionary.NOME],
            situacao: editObj[ProdutoDictionary.SITUACAO],
          });
        } else {
          form.resetFields();
        }
      }, [isModalEditarOpen, editObj, form]);

    return (
        <S.Container>
            <Modal style={{overflow: "hidden"}} key={isModalEditarOpen ? editObj?.id : 'create'} title="Cadastrar Produto" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <Form
                form={form}
                name="produto"
                initialValues={isModalEditarOpen ? {nome: editObj?.[ProdutoDictionary.NOME]} : undefined}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                label="Nome"
                name="nome"
                rules={[{ required: true, message: 'Por favor insira o nome da produto!' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Descrição"
                name="descricao"
                rules={[{ required: true, message: 'Por favor insira a descrição da produto!' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Categoria"
                name="categoria"
                rules={[{ required: true, message: 'Por favor insira a categoria do produto!' }]}
                >
                <Select
                    defaultValue="Categoria"
                    options={categoria}
                    />
                </Form.Item>

                <Form.Item
                label="Material"
                name="material"
                rules={[{ required: true, message: 'Por favor insira o material do produto!' }]}
                >
                <Select
                    defaultValue="Material"
                    options={material}
                    />
                </Form.Item>

                <Form.Item
                label="Tipo da pedra"
                name="tipoPedra"
                rules={[{ required: true, message: 'Por favor insira o tipo da pedra do produto!' }]}
                >
                <Select
                    defaultValue="Tipo da pedra"
                    options={tipoPedra}
                    />
                </Form.Item>
                <Form.Item
                label="Valor"
                name="preco"
                rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: 'Por favor, insira apenas números e ponto decimal.',
                    },
                  ]}
                >
                <Input />
                </Form.Item>

            <div style={{display: "flex", gap: 10}}>
                <Form.Item
                label="Tamanho"
                name="tamanho"
                rules={[{ required: true, message: 'Por favor insira o tamanho do produto!' }]}
                >
                <Select
                    dropdownStyle={{minWidth: '150px'}}
                    mode='multiple'
                    options={tamanho}
                    />
                </Form.Item>
                    <Button onClick={handleSelectAll} type='default'>Selecionar todos</Button>
            </div>

                <Form.Item
                label="Campanha"
                name="campanha"
                >
                <Select
                    defaultValue="Sem campanha"
                    options={campanha}
                    />
                </Form.Item>
                {isModalEditarOpen && (
                    <Form.Item
                    label="Ativo"
                    name="situacao"
                    >
                    <Switch defaultChecked={editObj[ProdutoDictionary.SITUACAO] === 1 ? true : false} />
                    </Form.Item>
                )}

                <Form.Item
                label="Observação"
                name="observacao"
                rules={[{ required: true, message: 'Por favor insira a descrição do produto!' }]}
                >
                <ReactQuill />
                </Form.Item>

                {isModalEditarOpen ? editObj[ProdutoDictionary.FOTOS].map((x: any)=> (
                    <img style={{marginRight: "10px", maxHeight: "100px"}} className='img' src={`${process.env.REACT_APP_API}${x[ProdutoDictionary.ARQUIVO]}`} alt="Imagem produto" />
                )) : (
                    <Form.Item
                    label="Imagem (Apenas png, jpeg, jpg e webp) - MIN 3 IMAGENS"
                    name="imagem"
                    >
                    <Upload
                    action="/"
                    listType="picture"
                    beforeUpload={handleUpload}
                    onRemove={handleRemove}
                    >
                    <Button icon={<i className="ri-folder-upload-line"></i>}>Upload</Button>
                    </Upload>
                    </Form.Item>
                )}

                <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
                </Form.Item>
            </Form>
            </Modal>
                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title-container'>
                        <div className='title'>
                            <i className="ri-shopping-bag-line ri-3x"></i>
                            <h1>Produtos</h1>
                        </div>
                        <Button onClick={() => {
                            setIsModalOpen(true)
                            setIsModalEditarOpen(false)
                        }} type='primary'>Cadastrar novo produto</Button>
                    </div>

                    <div>
                        <div className='banner-table-container'>

                            <Row className='title-table'>
                                <Col span={3}>
                                    <h4>Foto</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Nome</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Categoria</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Pedra</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Material</h4>
                                </Col>
                                <Col span={2}>
                                    <h4>Campanha</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Tamanho</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Cliques</h4>
                                </Col>
                                <Col span={1}>
                                    <h4>Ações</h4>
                                </Col>
                            </Row>
                            {produtos?.map((x) => (
                            <Row key={x[ProdutoDictionary.ID]} className='title-table'>
                                <Col span={3}>
                                <img style={{maxWidth: 150}} src={`${process.env.REACT_APP_API}${x[ProdutoDictionary.FOTOS]?.[0]?.[ProdutoDictionary.ARQUIVO]}`} alt={x[ProdutoDictionary.NOME]} />
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.NOME]}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.CATEGORIA][ProdutoDictionary.NOME_CATEGORIA]}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.TIPO_PEDRA][ProdutoDictionary.NOME_TIPO_PEDRA]}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.MATERIAL][ProdutoDictionary.NOME_MATERIAL]}</h5>
                                </Col>
                                <Col span={2}>
                                    <h5>{x[ProdutoDictionary.CAMPANHA] ? x[ProdutoDictionary.CAMPANHA]?.[ProdutoDictionary.NOME_CAMPANHA] : "Sem campanha"}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.TAMANHO]}</h5>
                                </Col>
                                <Col span={1}>
                                    <h5>{x[ProdutoDictionary.CLIQUES]}</h5>
                                </Col>
                                <Col className='actions' span={1}>
                                <Dropdown placement="bottomLeft" overlay={
                                    <Menu>
                                        {menuItems.map((item: any) => (
                                        <Menu.Item onClick={async () => {
                                            const edit = produtos.find(m => m[ProdutoDictionary.ID] === x[ProdutoDictionary.ID])

                                            if(item?.danger) {
                                                try {
                                                    await deleteProduto(x[ProdutoDictionary.ID].toString())
                                                    dispatch(getProdutos())
                                                } catch {
                                                    toast.error(`Ocorreu um erro ao deletar categoria ${x[ProdutoDictionary.NOME]}`)
                                                }
                                                dispatch(getProdutos())
                                            } else {
                                                setEditObj(edit)
                                                setIsModalEditarOpen(true);
                                                setIsModalOpen(true)
                                            }
                                        }} key={item?.key} danger={item?.danger}>
                                            {item.icon} {item.label}
                                        </Menu.Item>
                                        ))}
                                    </Menu>
                                    }>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <i style={{color: "black"}} className="ri-file-list-line ri-lg"></i>
                                    </a>
                                    </Dropdown>
                                </Col>
                            </Row>
                            ))}
                        </div>
            </div>
        </S.Container>
    )
}